import { Pipe, PipeTransform } from '@angular/core';
import { getNotEmpty } from '@workbench/common/utils/string-util';

@Pipe({
  name: 'fallback',
})
export class FallbackPipe implements PipeTransform {
  public transform(value: string, fallbackValue: string): string {
    return getNotEmpty(value, fallbackValue);
  }
}
