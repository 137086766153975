import { Pipe, PipeTransform } from '@angular/core';
import { ReasoningFaultKind } from '@workbench/business-logic/enums/reasoning-fault-kind.enum';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it } from '@workbench/common/utils/logical-utility';
import { isControlFunction } from '@workbench/multilevel-flow-modeling/core/mfm-core';

@Pipe({
  name: 'reasoningFaultKindIcon',
})
export class ReasoningFaultKindIconPipe implements PipeTransform {
  public transform(
    value: ReasoningFaultKind,
    concept?: ResourceConcept,
  ):
    | 'wb-state-breach'
    | 'wb-state-control-failure-high'
    | 'wb-state-control-failure-low'
    | 'wb-state-high'
    | 'wb-state-low'
    | 'wb-state-true'
    | 'wb-state-false'
    | undefined {
    if (value === ReasoningFaultKind.Breach) {
      return 'wb-state-breach';
    }
    if (value === ReasoningFaultKind.High) {
      if (it(isControlFunction(concept))) {
        return 'wb-state-control-failure-high';
      }

      return 'wb-state-high';
    }
    if (value === ReasoningFaultKind.Low) {
      if (it(isControlFunction(concept))) {
        return 'wb-state-control-failure-low';
      }

      return 'wb-state-low';
    }
    if (value === ReasoningFaultKind.True) {
      return 'wb-state-true';
    }
    if (value === ReasoningFaultKind.False) {
      return 'wb-state-false';
    }

    // eslint-disable-next-line no-undefined
    return undefined;
  }
}
