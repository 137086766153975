import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { equal, exist, it, not } from '@workbench/common/utils/logical-utility';
import { mxConstants, mxgraph } from '@workbench/dts/mxg';
import { getStyle } from '@workbench/mx-graph/utils/style-util';
import { isInfluenceRelation } from '../core/mfm-core';

type Geometry = {
  geometry:
    | {
        height: number;
        width: number;
        x: number;
        y: number;
        rotation: string;
      }
    | {
        points: { x: number; y: number }[];
        source: { x: number; y: number };
        target: { x: number; y: number };
      }
    | Record<string, never>;
};

export class GeometryProvider extends ObjectBuilderProvider<Geometry> {
  constructor(
    private readonly cell: mxgraph.mxCell,
    // TODO: This argument should be removed when we rid of XML based models
    private readonly defaultParentId: string,
  ) {
    super();
  }

  public get(): Geometry {
    if (this.cell == null) {
      return { geometry: {} };
    }
    if (this.cell.isVertex()) {
      return {
        geometry: {
          height: this.cell.geometry.height,
          width: this.cell.geometry.width,
          x: this.cell.geometry.x,
          y: this.cell.geometry.y,
          rotation: getStyle(this.cell.getStyle(), mxConstants.STYLE_ROTATION, '0'),
        },
      };
    }
    if (this.cell.isEdge()) {
      // 9014: For old WB models some Influence relations are deformed after reimport
      // It happens because of wrong points position, that is counted from relation`s old "parent", and gets to export file
      // By default, relation should not have parents, and its geometry is calculated depending of root element
      // So, if relation has "old" parent, and its not default root element
      // we add "old" parent coordinates to points coordinates

      if (it(isInfluenceRelation(() => this.cell.mfm.conceptId))) {
        const parent = this.cell.parent;

        if (
          it(
            exist(() => parent?.geometry),
            not(
              equal(
                () => parent?.id,
                () => this.defaultParentId,
              ),
            ),
          )
        ) {
          return {
            geometry: {
              points:
                this.cell.geometry?.points?.map((p: mxgraph.mxPoint) => ({
                  x: p.x + parent.geometry.x,
                  y: p.y + parent.geometry.y,
                })) ?? [],
              source: {
                x: this.cell.geometry?.sourcePoint?.x ?? 0,
                y: this.cell.geometry?.sourcePoint?.y ?? 0,
              },
              target: {
                x: this.cell.geometry?.targetPoint?.x ?? 0,
                y: this.cell.geometry?.targetPoint?.y ?? 0,
              },
            },
          };
        }
      }

      return {
        geometry: {
          points:
            this.cell.geometry?.points?.map((p: mxgraph.mxPoint) => ({ x: p.x, y: p.y })) ?? [],
          source: {
            x: this.cell.geometry?.sourcePoint?.x ?? 0,
            y: this.cell.geometry?.sourcePoint?.y ?? 0,
          },
          target: {
            x: this.cell.geometry?.targetPoint?.x ?? 0,
            y: this.cell.geometry?.targetPoint?.y ?? 0,
          },
        },
      };
    }
  }
}
