import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';

export class ActuatorProvider extends ObjectBuilderProvider<unknown> {
  constructor(
    private readonly source: {
      concept: ResourceConcept;
      actuatorTag: string;
      actuatorType: 'provisional' | '';
    },
    private readonly isAutomatic: boolean,
    private readonly isManual: boolean,
  ) {
    super();
  }

  public get(): {
    actuatorType: 'automatic' | 'manual' | 'provisional';
    actuatorTag: string;
  } | null {
    if (this.source.actuatorType === 'provisional') {
      return {
        actuatorType: 'provisional',
        actuatorTag: this.source.actuatorTag,
      };
    }
    if (this.isAutomatic === true) {
      return {
        actuatorType: 'automatic',
        actuatorTag: this.source.actuatorTag,
      };
    }
    if (this.isManual === true) {
      return {
        actuatorType: 'manual',
        actuatorTag: this.source.actuatorTag,
      };
    }

    return null;
  }
}
