import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';

export class MfmBaseProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; id: string }) {
    super();
  }

  public get(): MfmConcept {
    return {
      id: this.source.id,
      concept: this.source.concept,
    } as MfmConcept;
  }
}
