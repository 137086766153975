import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Issue: Disabled components don't emit some mouse events, thus a mat-tooltip couldn't be shown.
 *
 * This component wraps a disabled element and displays a tooltip.
 * You can use it as a tooltip for disabled state only (with undefined `show` value) or for both states
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wb-tooltip-wrapper',
  templateUrl: './tooltip-wrapper.component.html',
})
export class TooltipWrapperComponent {
  @Input() public tooltip = '';
  @Input() public show = true;
}
