import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Option } from '@workbench/common/models/option';
import { escape } from '@workbench/common/utils/string-util';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: AutocompleteComponent }],
  selector: 'wb-autocomplete',
  styleUrls: ['autocomplete.component.scss'],
  templateUrl: 'autocomplete.component.html',
})
export class AutocompleteComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  private static nextId = 0;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') public userAriaDescribedBy: string;

  @ViewChild('input') public inputElement: HTMLInputElement;
  @ViewChild(MatAutocomplete) public readonly autocomplete: MatAutocomplete;

  @HostBinding() public readonly id = `wb-autocomplete-${AutocompleteComponent.nextId++}`;
  @HostBinding('attr.aria-labelledby') public readonly ariaLabelledBy =
    this.formField?.getLabelId();

  public readonly controlType = 'wb-autocomplete';
  public focused = false;
  public readonly inputFormControl = this.formBuilder.control('');
  public readonly stateChanges = new Subject<void>();
  public touched = false;

  public readonly filteredOptions: Observable<Option[]>;

  /* eslint-disable */
  private _currentValue = '';
  private _disabled = false;
  private _options: Option[] = [];
  private _placeholder = '';
  private _required = false;
  private _readonly = false;
  /* eslint-enable */

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly focusMonitor: FocusMonitor,
    private readonly formBuilder: UntypedFormBuilder,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.filteredOptions = this.inputFormControl.valueChanges.pipe(
      startWith(''),
      map(value => escape(value.trimStart())),
      map(value => this.options.filter(op => new RegExp(`${value}`, 'gi').test(op.label))),
    );
    this.inputFormControl.valueChanges.subscribe(() => {
      this.onChange(this.value);
    });
  }

  public get empty(): boolean {
    return (this.inputFormControl.value ?? '').length === 0;
  }

  public get errorState(): boolean {
    return this.inputFormControl.invalid === true && this.touched === true;
  }

  public get shouldLabelFloat(): boolean {
    return this.focused === true || this.empty === false;
  }

  @HostListener('focusin', ['$event']) private onHostFocusIn(event: FocusEvent): void {
    if (this.focused === false) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  @HostListener('focusout', ['$event']) private onHostFocusOut(event: FocusEvent): void {
    if (this.elementRef.nativeElement.contains(event.relatedTarget as Element) === false) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  public onChange = (value: string): void => {};
  public onTouched = (): void => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get options(): Option[] {
    return this._options;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set options(value: Option[]) {
    this._options = value ?? [];
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set placeholder(value: string) {
    this._placeholder = value ?? '';
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get required(): boolean {
    return this._required;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get readonly(): boolean {
    return this._readonly;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
    if (this._readonly === true) {
      this.inputFormControl.disable();
    }
    if (this._readonly === false) {
      this.inputFormControl.enable();
    }
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled === true) {
      this.inputFormControl.disable();
    }
    if (this._disabled === false) {
      this.inputFormControl.enable();
    }
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  public get value(): string {
    const value: string = this.inputFormControl.value?.label ?? this.inputFormControl.value ?? '';

    // Qualify an empty string as a valid value (the option is reset or not selected)
    if (value.trimStart().length === 0) {
      return '';
    }

    const userValue = new RegExp(`^${escape(value)}$`, 'gi');
    const userOption = this.options.find(option => userValue.test(option.label));

    // The value must be contained in the number of provided options,
    // otherwise, the current value is returned
    return this.inputFormControl.valid === true
      ? userOption?.label ?? this._currentValue
      : this._currentValue;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public set value(value: string) {
    this._currentValue = value;
    this.inputFormControl.setValue(this._currentValue, { emitEvent: false });
    this.stateChanges.next();
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public onContainerClick(): void {
    this.focusMonitor.focusVia(this.inputElement, 'program');
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDescribedByIds(ids: string[]): void {
    this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(tel: string): void {
    this.value = tel;
  }
}
