import { idt, it, or } from '@workbench/common/utils/logical-utility';

import {
  isAC,
  isBAR,
  isControlFunction,
  isIN,
  isIP,
  isObjective,
  isPA,
  isPP,
  isTRA,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { selectModelConnections, selectModelMap } from './model-builder.selectors';

/**
 * Explore the tree of connections starting from the provided Control Function
 * and find all Actuators based on a rule.
 * Control Function has to be connected using Actuate with a Transport/Barrier
 * and then using Producer-product/Inverse Producer-product connected with
 * a Transport/Barrier (both have to be the same type – Barrier or Transport)
 *
 * @param model an MFM model
 * @returns a Set of IDs found Actuators
 */
export function selectManualActuators(
  model: ReturnType<typeof selectModelMap>,
  connections: ReturnType<typeof selectModelConnections>,
): Set<string> {
  // Objective -PA-> Control -AC-> TRA -PP-> TRA
  const OBJECTIVE_PA_CONTROL = Array.from(model.values())
    .filter(x => it(isObjective(() => x.concept)))
    // Pick only Objective -PA-> Control
    .flatMap(({ id }) =>
      (connections.get(id) ?? [])
        .filter(({ edge, target }) =>
          it(isControlFunction(target?.concept), isPA(idt(edge.concept))),
        )
        .map(({ target: { id: targetId } }) => targetId),
    );

  const TAR_PA_CONTROL_AC_TAR_TAR = OBJECTIVE_PA_CONTROL
    // Pick only Control -AC-> TRA
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) => it(isAC(idt(edge.concept)), isTRA(idt(target?.concept))));

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    })
    // Pick only TRA -PP/IP-> TRA
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) =>
            it(isTRA(idt(target?.concept)), or(isPP(idt(edge.concept)), isIP(idt(edge.concept)))),
          );

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    });
  const OBJECTIVE_PA_MCO_AC_BAR_BAR = OBJECTIVE_PA_CONTROL
    // Pick only MCO -AC-> BAR
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) => it(isAC(idt(edge.concept)), isBAR(idt(target?.concept))));

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    })
    // Pick only BAR -PP/IP-> BAR
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) =>
            it(isBAR(idt(target?.concept)), or(isPP(idt(edge.concept)), isIP(idt(edge.concept)))),
          );

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    });

  return new Set<string>([...OBJECTIVE_PA_MCO_AC_BAR_BAR, ...TAR_PA_CONTROL_AC_TAR_TAR]);
}

export function selectAutomaticActuators(
  model: ReturnType<typeof selectModelMap>,
  connections: ReturnType<typeof selectModelConnections>,
): Set<string> {
  // Objective -IN-> Control -AC-> TRA -PP-> TRA
  const OBJECTIVE_IN_CONTROL = Array.from(model.values())
    .filter(x => it(isObjective(() => x.concept)))
    // Pick only Objective -PA-> Control
    .flatMap(({ id }) =>
      (connections.get(id) ?? [])
        .filter(({ edge, target }) =>
          it(isControlFunction(target?.concept), isIN(idt(edge.concept))),
        )
        .map(({ target: { id: targetId } }) => targetId),
    );

  const OBJECTIVE_IN_CONTROL_AC_TAR_TAR = OBJECTIVE_IN_CONTROL
    // Pick only Control -AC-> TRA
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) => it(isAC(idt(edge.concept)), isTRA(idt(target?.concept))));

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    })
    // Pick only TRA -PP.IP-> TRA
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) =>
            it(isTRA(idt(target?.concept)), or(isPP(idt(edge.concept)), isIP(idt(edge.concept)))),
          );

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    });
  const OBJECTIVE_IN_CONTROL_AC_BAR_BAR = OBJECTIVE_IN_CONTROL
    // Pick only Control -AC-> BAR
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) => it(isAC(idt(edge?.concept)), isBAR(idt(target?.concept))));

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    })
    // Pick only BAR -PP/IP-> BAR
    .flatMap(id => {
      if (connections.get(id)?.length === 2) {
        const connection = connections
          .get(id)
          .find(({ edge, target }) =>
            it(isBAR(idt(target?.concept)), or(isPP(idt(edge.concept)), isIP(idt(edge.concept)))),
          );

        if (connection) {
          return [connection.target.id];
        }
      }

      return [];
    });

  return new Set<string>([...OBJECTIVE_IN_CONTROL_AC_BAR_BAR, ...OBJECTIVE_IN_CONTROL_AC_TAR_TAR]);
}
