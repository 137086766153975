import { ObjectBuilderProvider } from './object-builder-provider';

export class ObjectBuilder<TResult> {
  private readonly item: TResult = Object.create(null);

  public use(provider: ObjectBuilderProvider<Partial<TResult>>): this {
    Object.assign(this.item, provider.get());

    return this;
  }

  public get(): TResult {
    return this.item;
  }
}
