import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import { toMap } from '@workbench/common/utils/array-util';
import { isEmptyStr, it, not } from '@workbench/common/utils/logical-utility';
import { getNotEmpty, isNullOrEmpty } from '@workbench/common/utils/string-util';
import { LibraryModel } from '@workbench/state/library';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { isSUB } from '../core/mfm-core';

interface Response {
  guid: string;
  name: string;
  input: unknown[];
  output: unknown[];
  sinks: unknown[];
  sources: unknown[];
}

export class SubModelProvider extends ObjectBuilderProvider<{ model: Response }> {
  constructor(
    private readonly mfm: MfmConceptConfiguration,
    private readonly subModel?: LibraryModel,
  ) {
    super();
  }

  public get(): { model: Response & { tagging: LibraryTagging } } | undefined {
    // To be exported, the sub-model must have a library model linked to it.
    if (
      it(
        isSUB(() => this.mfm.concept),
        not(isEmptyStr(this.mfm.subModelGuid)),
      )
    ) {
      const model = {
        guid: this.mfm.subModelGuid,
        uncontrolled: this.mfm.subModelCanBeUpdated,
        name: this.mfm.subModelName,
        timestamp: this.mfm.subModelVersion,
        input: this.mfm.subModelTerminals?.inputs ?? [],
        output: this.mfm.subModelTerminals?.outputs ?? [],
        sinks: this.mfm.subModelTerminals?.sinks ?? [],
        sources: this.mfm.subModelTerminals?.sources ?? [],
        tagging: null,
      };

      if (isNullOrEmpty(this.mfm.subModelGuid) === false) {
        const actuator = toMap(this.mfm.subModelTagging?.actuator ?? [], 'id');
        const controller = toMap(this.mfm.subModelTagging?.controller ?? [], 'id');
        const processVariable = toMap(this.mfm.subModelTagging?.processVariable ?? [], 'id');

        const tagging = {
          actuator: (this.subModel?.tagging?.actuator ?? []).map(
            ({ badge, global, id, local, state }) => ({
              badge,
              global: getNotEmpty(actuator.get(id)?.global),
              id,
              local,
              state: getNotEmpty(actuator.get(id)?.state, state),
            }),
          ),
          controller: (this.subModel?.tagging?.controller ?? []).map(
            ({ badge, global, id, local, state }) => ({
              badge,
              global: getNotEmpty(controller.get(id)?.global),
              id,
              local,
              state: getNotEmpty(controller.get(id)?.state, state),
            }),
          ),
          equipment: (this.subModel?.tagging?.equipment ?? []).map(
            ({ badge, global, submodelId, local, state }) => ({
              badge,
              global:
                this.mfm.subModelTagging?.equipment.find(
                  y => `${y.submodelId}.${y.local}` === `${submodelId}.${local}`,
                )?.global ?? global,
              local,
              state:
                this.mfm.subModelTagging?.equipment.find(
                  y => `${y.submodelId}.${y.local}` === `${submodelId}.${local}`,
                )?.state ?? state,
              submodelId,
            }),
          ),
          processVariable: (this.subModel?.tagging?.processVariable ?? []).map(
            ({ badge, expression, global, id, local, state, type }) => ({
              badge,
              expression: getNotEmpty(processVariable.get(id)?.expression, expression),
              global: getNotEmpty(processVariable.get(id)?.global),
              id,
              local,
              state: getNotEmpty(processVariable.get(id)?.state, state),
              type: getNotEmpty(processVariable.get(id)?.type, type),
            }),
          ),
        };

        Object.assign(model, { tagging });
      }

      return { model };
    }
  }
}
