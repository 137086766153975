<ng-container *ngIf="items.length > 0; else elseTemplate">
    <ng-container *ngFor="let item of items; trackBy:trackBy">
        <div
            (click)="onSelectionChange(item.uid)"
            [class.causal-analysis-list__item_selected]="selectedItem === item.uid"
            [class.causal-analysis-list__item_unreachable]="item.unreachable === true"
            class="causal-analysis-list__item"
        >
            <mat-icon
                [class.causal-analysis-list__item-concept_type_end-consequences]="kind === CONSEQUENCES"
                [class.causal-analysis-list__item-concept_type_root-causes]="kind === CAUSES"
                [svgIcon]="item.concept | conceptIcon"
                class="causal-analysis-list__item-concept"
            ></mat-icon>
            <mat-icon
                [svgIcon]="item.state | reasoningFaultKindIcon : item.concept"
                class="causal-analysis-list__item-state"
            ></mat-icon>
            <div class="causal-analysis-list__item-label"> {{ item.label }} </div>
            <div class="causal-analysis-list__item-description">
                {{ item.description | fallback : 'No description' }}
            </div>
            <mat-icon
                *ngIf="item.containsInvalidData === true"
                class="causal-analysis-list__item-info"
            > info </mat-icon>
            <mat-icon
                *ngIf="item.unreachable === true"
                class="causal-analysis-list__item-info"
                matTooltip="The concept is a part of a library component and can't be reached"
            > directions_off </mat-icon>
        </div>
    </ng-container>
</ng-container>
<ng-template #elseTemplate>
    <p style="text-align: center; margin-top: 8px"> There are no items to display </p>
</ng-template>