import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { interval, map, Observable, skip, startWith, take } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification.component.scss'],
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  public readonly timer$: Observable<number> = null;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { title: string; message: string; numOfMessages: number; duration: number },
    private readonly snackBarRef: MatSnackBarRef<NotificationComponent>,
  ) {
    if (data?.duration > 0) {
      this.timer$ = this.runTimer(data.duration);
      this.timer$.subscribe({ complete: () => this.snackBarRef.dismiss() });
    }
  }

  public onActionClick(): void {
    this.snackBarRef.dismissWithAction();
  }

  private runTimer(duration: number, step = 100): Observable<number> {
    return interval(step).pipe(
      startWith(duration),
      skip(1), // Skip the initial value
      take((duration + step) / step + 1), // Take the number of values needed
      map(x => ((duration - x * step) / duration) * 100), // Calculate the progress
      map(x => Math.max(0, x)), // Ensure the progress is not negative
    );
  }
}
