<input
    #input
    [formControl]="inputFormControl"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    class="input-element"
>
<mat-autocomplete #auto="matAutocomplete">
    <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option.label"
    > {{ option.label }} </mat-option>
</mat-autocomplete>