export type Predicate = Func<boolean>;

export function idt<T>(arg: T): Func<T> {
  return (): T => arg;
}

export const it = (...conditions: Predicate[]): boolean => conditions.every(c => truly(c()));

export const emptyStr = (): '' => '';
export const isEmptyStr = (str: string, ...rest: string[]): Predicate =>
  equal(emptyStr, idt(str), ...rest.map(idt));

export const and =
  (...conditions: Predicate[]): Predicate =>
  (): boolean =>
    conditions.every(x => truly(x()));

export const contain =
  (value: Func<unknown>, ...values: Func[]): Predicate =>
  (): boolean =>
    values.some(x => x() === value());

export const equal =
  (...args: Func[]): Predicate =>
  (): boolean =>
    args.every(x => args[0]() === x());

export const exist =
  (...args: Func[]): Predicate =>
  (): boolean =>
    args.every(x => falsy(isNullOrUndefined(x?.())));

export const not =
  (...conditions: Predicate[]): Predicate =>
  (): boolean =>
    conditions.every(x => falsy(x()));

export const or =
  (...conditions: Predicate[]): Predicate =>
  (): boolean =>
    conditions.length > 0 ? conditions.some(c => truly(c())) : true;

const falsy = (x: unknown): boolean => (x ?? false) === false;
const isNullOrUndefined = (x: unknown): boolean => (x ?? null) === null;
const truly = (x: unknown): boolean => (x ?? false) === true;

type Func<R = unknown> = () => R;
