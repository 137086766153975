import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Option } from '@workbench/common/models/option';
import { emptyStr, equal, exist, it, not } from '@workbench/common/utils/logical-utility';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wb-option-and-description-editor',
  styleUrls: ['./option-and-description-editor.component.scss'],
  templateUrl: './option-and-description-editor.component.html',
})
export class OptionAndDescriptionEditorComponent implements OnInit, OnChanges {
  @Input() public description = '';
  @Input() public descriptionLabel = 'Description';
  @Input() public descriptionPlaceholder = 'Add description';
  @Input() public options: (Option & { description: string })[] = [];
  @Input() public optionsLabel = 'Options';
  @Input() public optionsPlaceholder = 'Select item';
  @Input() public selectedId = '';

  @Output() public readonly discardChanges = new EventEmitter();
  @Output() public readonly submitChanges = new EventEmitter();
  @Output() public readonly valueChange = new EventEmitter<
    Partial<{
      selectedId: string;
      description: string;
    }>
  >();

  public readonly form = this.fb.group(
    {
      description: this.fb.control({ value: '', disabled: true }, Validators.required),
      option: this.fb.control('', Validators.required),
    },
    { updateOn: 'blur' },
  );

  constructor(private readonly fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.form.get('description').valueChanges.subscribe(description => {
      this.valueChange.emit({ description });
    });
    this.form.get('option').valueChanges.subscribe(selectedId => {
      this.valueChange.emit({
        description: this.options.find(option => option.id === selectedId)?.description ?? '',
        selectedId,
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.description) {
      this.form.patchValue({ description: this.description }, { emitEvent: false });
    }
    if (changes.selectedId) {
      const selectedId = (): string => changes.selectedId.currentValue;

      if (it(exist(selectedId), not(equal(selectedId, emptyStr)))) {
        this.form.get('description').enable({ emitEvent: false });
      }
      this.form.patchValue({ option: this.selectedId }, { emitEvent: false });
    }
  }

  public onClearDescriptionClick(): void {
    this.valueChange.emit({ description: '' });
  }

  public onDiscard(): void {
    this.discardChanges.emit();
  }

  public onSubmit(): void {
    this.submitChanges.emit();
  }
}
