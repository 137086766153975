import { intersection } from './set-util';

/**
 * Compares two maps for equality.
 *
 * @param a - The first map to compare.
 * @param b - The second map to compare.
 * @param compare - An optional function to compare the values of the maps.
 * @returns True if the maps are equal, false otherwise.
 */
export function compareMaps<T>(
  a: Map<string, T>,
  b: Map<string, T>,
  compare?: (aValue: T, bValue: T) => boolean,
): boolean {
  const common = intersection(new Set(a.keys()), new Set(b.keys()));

  return (
    a.size === b.size &&
    common.length === a.size &&
    common.every(key => compare?.(a.get(key) as T, b.get(key) as T) ?? a.get(key) === b.get(key))
  );
}

/**
 * Assigns the key-value pairs from Map `b` to Map `a` and returns the resulting Map.
 * If a key already exists in Map `a`, its corresponding value will be overwritten with the value from Map `b`.
 *
 * @param a - The target Map to assign the key-value pairs to.
 * @param b - The source Map containing the key-value pairs to assign.
 * @returns The resulting Map after assigning the key-value pairs from Map `b` to Map `a`.
 */
export function assignMap<T>(a: Map<string, T>, b: Map<string, T>): Map<string, T> {
  return Array.from(b.entries()).reduce((m, [k, v]) => m.set(k, v), new Map(a));
}
