import { emptyStr, equal, it, not, or } from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import {
  isCFS,
  isEFS,
  isMEQ,
  isMFS,
  isRelation,
  isStructure,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { MfmConceptConfiguration } from '../mfm-model.model';

/**
 * Extracts a set of equipment from the MFM model based on the specific properties that each concept has.
 * Elements (MFM functions and targets) can be assigned to equipment groups in three ways:
 * by inclusion in the equipment structure, in the MFM (mass, energy, or flow) structure, or through an equipment property.
 *
 * Any item can be part of one and only one group of equipment.
 * Priority in resolving this:
 * 1. assigned group at the element level;
 * 2. MFM parent structure (CFS, MFS, EFS);
 * 3. parent equipment structure (MEQ);
 *
 * @param mfmModel simply a MFM model
 * @returns [string, Set<String>][] an array that consists of pairs of equipment tags and IDs of functions that included in that equipment
 */
export const selectEquipment = (mfmModel: MfmConceptConfiguration[]): [string, Set<string>][] => {
  // All concepts of the model that are not a relation
  const allNonRelationConcepts = mfmModel.filter(mfm => it(not(isRelation(() => mfm.concept))));
  // and those that have a tag property defined
  const taggedConcepts = allNonRelationConcepts.filter(n =>
    it(not(equal(() => getNotEmpty(n.tag), emptyStr))),
  );

  // Now, let's pick all concepts that are not a structure and group them by a tag
  const single = taggedConcepts
    .filter(x => it(not(isStructure(() => x.concept))))
    .reduce<Map<string, Set<MfmConceptConfiguration>>>(
      (acc, concept) => groupByReducer('tag', acc, concept),
      new Map(),
    );

  // Now, let's pick all concepts that are CFS, EFS, or MFS and group them by a tag
  const flowStructures = taggedConcepts
    .filter(n =>
      it(
        or(
          isCFS(() => n.concept),
          isMFS(() => n.concept),
          isEFS(() => n.concept),
        ),
      ),
    )
    .reduce<Map<string, Set<MfmConceptConfiguration>>>(
      (acc, item) => groupByReducer('tag', acc, item),
      new Map(),
    );

  // And now, let's pick all concepts that are an Equipment structure and group them by a tag
  const equipmentStructures = taggedConcepts
    .filter(n => it(isMEQ(() => n.concept)))
    .reduce<Map<string, Set<MfmConceptConfiguration>>>(
      (acc, item) => groupByReducer('tag', acc, item),
      new Map(),
    );

  // Set of concepts that have been already included in any equipment group.
  // Thus we can avoid the second appearance of any item
  const visitedIds = new Set<string>();

  return Array.from(
    // Array of equipment groups in priority order
    [single, flowStructures, equipmentStructures]
      .reduce<Map<string, Set<string>>>(
        // Create a final Map (tag-concepts) that represents a model's Equipment
        (equipment, group) =>
          equipmentReducer(equipment, group, visitedIds, allNonRelationConcepts),
        new Map(),
      )
      .entries(),
  );
};

// Deep search the children. Ignore (doesn't include it) a concept (in this case a structure)
// if it has children, and add its children recursively instead
function getChildren(
  id: string,
  all: MfmConceptConfiguration[],
  includeParentIfNoChildren: boolean,
): string[] {
  const children = all.reduce(
    (acc, x) =>
      x.parentId === id
        ? [...acc, ...getChildren(x.id, all, it(not(isStructure(() => x.concept))))]
        : acc,
    [],
  );

  // If the concept (that is not a structure) doesn't have children
  // then it should be added as a child, otherwise not
  return children.length > 0 ? children : includeParentIfNoChildren ? [id] : [];
}

function groupByReducer<V>(
  prop: keyof V,
  groups: Map<string, Set<V>>,
  item: V,
): Map<string, Set<V>> {
  if (groups.has(String(item[prop])) === false) {
    return groups.set(String(item[prop]), new Set([item]));
  }
  groups.get(String(item[prop])).add(item);

  return groups;
}

function equipmentReducer(
  equipment: Map<string, Set<string>>,
  group: Map<string, Set<MfmConceptConfiguration>>,
  visitedIds: Set<string>,
  all: MfmConceptConfiguration[],
): Map<string, Set<string>> {
  Array.from(group.entries()).forEach(([k, v]) => {
    if (equipment.has(k) === false) {
      equipment.set(k, new Set());
    }
    Array.from(v.values())
      .reduce(
        (set, element) => (
          getChildren(element.id, all, it(not(isStructure(() => element.concept)))).forEach(id =>
            set.add(id),
          ),
          set
        ),
        new Set<string>(),
      )
      .forEach(id => {
        if (visitedIds.has(id) === false) {
          visitedIds.add(id);
          equipment.get(k).add(id);
        }
      });
  });

  return equipment;
}
