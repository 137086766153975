import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { exist, it } from '@workbench/common/utils/logical-utility';
import { CausalAnalysisItemDescription } from '@workbench/state/model-builder/causal-analysis-item-description.model';

interface Labels {
  labels?: Partial<{
    ec: {
      description: string;
      iso: string;
      label: string;
      mitigation: string;
      preventive: string[];
    };
    inspection: { description: string };
    preventive: { description: string };
    rc: {
      corrective: string[];
      description: string;
      inspection: string[];
      iso: string;
      label: string;
      mitigation: string;
    };
  }>;
}

export class CausalAnalysisDescriptionProvider extends ObjectBuilderProvider<Labels> {
  constructor(private readonly source: CausalAnalysisItemDescription) {
    super();
  }

  public get(): Labels {
    let result: Labels;

    if (it(exist(() => this.source))) {
      result ??= { labels: {} };
    }
    if (it(exist(() => this.source?.endConsequence))) {
      result.labels.ec = {
        description: this.source.endConsequence.description,
        iso: this.source.endConsequence.iso,
        label: this.source.endConsequence.label,
        mitigation: this.source.endConsequence.mitigation,
        preventive: this.source.endConsequence.preventiveActions,
      };
    }
    if (it(exist(() => this.source?.inspectionPoint))) {
      result.labels.inspection = { description: this.source.inspectionPoint.description };
    }
    if (it(exist(() => this.source?.preventiveAction))) {
      result.labels.preventive = { description: this.source.preventiveAction.description };
    }
    if (it(exist(() => this.source?.rootCause))) {
      result.labels.rc = {
        // We're converting corrective actions to an array for backward compatibility with the CRA
        corrective: [this.source.rootCause.correctiveActions],
        description: this.source.rootCause.description,
        inspection: this.source.rootCause.inspectionPoints,
        iso: this.source.rootCause.iso,
        label: this.source.rootCause.label,
        mitigation: this.source.rootCause.mitigation,
      };
    }

    return result;
  }
}
