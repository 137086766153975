import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MfmConceptConfiguration } from './mfm-model.model';

export interface MfmConceptState extends EntityState<MfmConceptConfiguration> {
  pointedId: string;
  selectedId: string[];
  stashed?: MfmConceptConfiguration;
  copied?: MfmConceptConfiguration[];
}

export const adapter: EntityAdapter<MfmConceptConfiguration> =
  createEntityAdapter<MfmConceptConfiguration>();

export const initialState: MfmConceptState = adapter.getInitialState({
  pointedId: '',
  selectedId: [],
});
