import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wb-badge',
  template: `<span class="badge-label"> {{ label }} </span>`,
  styles: [
    `
      @use '@angular/material' as mat;
      @use 'wb/theme';

      :host {
        background-color: mat.get-color-from-palette(theme.$accent, 400);
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        min-width: 2em;
        padding: 0.3em 0.5em;

        > .badge-label {
          color: mat.get-color-from-palette(theme.$accent, 900);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() public label: string | number;
}
