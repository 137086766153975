import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it, or } from '@workbench/common/utils/logical-utility';
import { isHAZ, isSHA, isSIN, isSOU, isTAR } from '../core/mfm-core';

export class ObjectRoleProvider extends ObjectBuilderProvider<{ objectRole: string }> {
  constructor(private readonly source: { concept: ResourceConcept; objectRole: string }) {
    super();
  }

  public get(): { objectRole: string } | undefined {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(or(isSHA(concept), isSIN(concept), isSOU(concept), isTAR(concept), isHAZ(concept)))) {
      return {
        objectRole: this.source.objectRole,
      };
    }
  }
}
