/**
 * Replaces placeholders in a string with provided arguments.
 *
 * @param template - The string containing placeholders in the format of {0}, {1}, etc.
 * @param args - The arguments to replace the placeholders with.
 * @returns The formatted string.
 * @example
 * formatString('Hello, {0}!', 'world'); // 'Hello, world!'
 * formatString('Hello, {0}! Welcome to {1}!', 'world', 'Earth'); // 'Hello, world! Welcome to Earth!'
 * formatString('Hello, {0}! Welcome to {1}!', 'world'); // 'Hello, world! Welcome to {1}!'
 * formatString('Hello, {1}! Welcome to {0}!', 'world', 'Earth'); // 'Hello, Earth! Welcome to world!'
 * formatString('Hello, {0}! Welcome to {1}!', 'world', 'Earth', 'Universe'); // 'Hello, world! Welcome to Earth!'
 */
export const formatString = (template: string, ...args: string[]): string =>
  template.replace(/{(\d+)}/g, (match: string, idx: number) => args?.[idx] ?? match);

/**
 * Capitalizes the first letter of a string.
 *
 * @param source - The input string.
 * @returns The input string with the first letter capitalized.
 */
export const capitalize = (source: string): string =>
  `${source[0]?.toUpperCase() ?? ''}${source.substring(1)}`;

/**
 * Checks if a string ends with a specified tail string.
 *
 * @param source - The source string to check.
 * @param tail - The tail string to compare with.
 * @returns A boolean indicating whether the source string ends with the tail string.
 */
export const endsWith = (source: string, tail: string): boolean => {
  const sourceLength = source.length;
  const tailLength = tail.length;

  return sourceLength >= tailLength && source.slice(sourceLength - tailLength) === tail;
};

/**
 * Checks if all provided strings are equal.
 *
 * @param sensitive - Whether the comparison should be case-sensitive.
 * @param args - The strings to compare.
 * @returns True if all strings are equal, false otherwise.
 */
export const equalStrings = (sensitive: boolean, ...args: string[]): boolean =>
  args.length > 1
    ? args
        .map(s => s ?? '')
        .map(s => (sensitive ? s : s.toLowerCase()))
        .every((s, index, array) => s === array[0])
    : true;

/**
 * Returns the first non-empty string from the provided values.
 *
 * @param values The string values to check.
 * @returns The first non-empty string, or an empty string if all values are empty.
 */
export const getNotEmpty = (...values: string[]): string =>
  values.find(x => String(x ?? '').length > 0)?.toString() ?? '';

/**
 * Checks if a string is null, undefined, or empty.
 *
 * @param value - The string to check.
 * @returns True if the string is null, undefined, or empty; otherwise, false.
 */
export const isNullOrEmpty = (value: string | undefined | null): boolean =>
  (value ?? null) === null || value.length === 0;

// For the reference https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export const escape = (value: string): string => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
