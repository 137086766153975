import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wb-task-monitor',
  styleUrls: ['./task-monitor.component.scss'],
  templateUrl: './task-monitor.component.html',
})
export class TaskMonitorComponent {
  @Input() public tasks: [string, string][] = [];
}
