<div
    *ngIf="titleText?.length > 0"
    class="card__header"
>
    <mat-icon
        *ngIf="icon"
        color="primary"
    > {{ icon }} </mat-icon>
    <span class="card__title"> {{ titleText }} </span>
    <span
        class="card__secondary-title"
        *ngIf="badge.length > 0"
    > {{ badge }} </span>
</div>
<div class="card__content">
    <ng-content></ng-content>
</div>
