import { createAction, props } from '@ngrx/store';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import { LibraryModel } from '.';

export const setLibraryModel = createAction(
  '[Library] Set Library Model',
  props<{ model: LibraryModel }>(),
);
export const setLibraryModels = createAction(
  '[Library] Set Library Models',
  props<{ models: LibraryModel[] }>(),
);
export const setLibraryModelTags = createAction(
  '[Library] Set Library Model Tags',
  props<{ key: string; tagging: LibraryTagging }>(),
);
export const updateLibraryModels = createAction(
  '[Library] Update Library Models',
  props<{ models: LibraryModel[] }>(),
);
export const updateLibraryModelTags = createAction(
  '[Library] Update Library Model Tags',
  props<{ key: string; tagging: LibraryTagging }>(),
);
