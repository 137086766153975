import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from '@ngrx/store';
import { ObjectBuilder } from '@workbench/common/converter/object-builder';
import { isExposed } from '@workbench/common/types/exposure-state-value.type';
import { isProcessVariable } from '@workbench/common/types/process-variable-value.type';
import { toMap } from '@workbench/common/utils/array-util';
import { it } from '@workbench/common/utils/logical-utility';
import { mxgraph } from '@workbench/dts/mxg';
import { FlatFileBuilder } from '@workbench/multilevel-flow-modeling/builders/flat-file-builder';
import { GeometryProvider } from '@workbench/multilevel-flow-modeling/builders/geometry-provider';
import { MfmDescriptionProvider } from '@workbench/multilevel-flow-modeling/builders/mfm-description-provider';
import { ModelBuilder } from '@workbench/multilevel-flow-modeling/builders/model-builder';
import { isSUB } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { AppState } from '@workbench/state';
import { selectLibrary } from '@workbench/state/library/library.selectors';
import * as fromCausalAnalysisDescription from '../causal-analysis-description.reducer';
import * as fromMfmConcept from '../mfm-concept.reducer';
import { MfmConceptConfiguration } from '../mfm-model.model';
import { ModelBuilderState } from '../model-builder.reducer';
import { selectAutomaticActuators, selectManualActuators } from './model-actuators.selector';
import { selectConnections } from './model-connections.selector';
import { selectEquipment } from './model-equipment.selector';
import { selectReliefSystems } from './model-relief-systems.selector';
import { selectSensors } from './model-sensors.selector';

export type ModelActuators = {
  all: Set<string>;
  automatic: Set<string>;
  manual: Set<string>;
  provisional: Set<string>;
};

const selectFeatureState = createFeatureSelector<ModelBuilderState>('model');
const selectMfmConceptState = createSelector(selectFeatureState, model => model.concepts);
const selectCausalAnalysisDescription = createSelector(
  selectFeatureState,
  model => model.causalAnalysisDescription,
);
const selectSelectedIds = createSelector(selectMfmConceptState, s => s.selectedId);
const selectPointedConceptId = createSelector(selectMfmConceptState, s => s.pointedId);
const selectProjectPermissions = createSelector(selectFeatureState, s => s.permissions);

export const selectImportedModel = createSelector(selectFeatureState, s => s.importedModel);
export const selectModelVersionOpened = createSelector(selectFeatureState, s => s.versionOpened);
export const selectMfmModel = createSelector(
  selectMfmConceptState,
  fromMfmConcept.adapter.getSelectors().selectAll,
);
export const selectMfmModelEntities = createSelector(
  selectMfmConceptState,
  fromMfmConcept.adapter.getSelectors().selectEntities,
);
export const selectCopiedConcepts = createSelector(selectMfmConceptState, state =>
  toMap(state.copied ?? [], 'id'),
);
export const selectStashedConcept = createSelector(selectMfmConceptState, state => state.stashed);
export const selectCausalAnalysisDescriptionList = createSelector(
  selectCausalAnalysisDescription,
  fromCausalAnalysisDescription.adapter.getSelectors().selectAll,
);
export const selectProject = createSelector(selectFeatureState, s => s.project);
export const selectProjectId = createSelector(selectFeatureState, s => s.id);
export const selectProjectName = createSelector(selectProject, project => project?.name ?? '');
export const selectProjectLock = createSelector(selectProject, project => project?.lock ?? null);
export const selectProjectLocked = createSelector(selectProjectLock, lock => lock !== null);
export const selectProjectLockedBy = createSelector(selectProjectLock, lock => lock?.name ?? '');
export const selectProjectVersion = createSelector(selectProject, p => p?.version ?? '');
export const selectCanModify = createSelector(selectProjectPermissions, ({ write }) => write);
export const selectPointedConcept = createSelector(
  selectPointedConceptId,
  selectMfmModelEntities,
  (id, model) => model[id] ?? null,
);
export const selectSyntaxIssues = createSelector(selectFeatureState, s => s.syntaxIssues);
export const selectHasInvalidSubModels = createSelector(
  selectFeatureState,
  s => s.invalidSubModels.length > 0,
);

export const selectMode = createSelector(selectFeatureState, s => s.mode);

export const selectModel = createSelector(selectMfmModel, model => new ModelBuilder(model).build());
export const selectModelMap = createSelector(selectMfmModel, model => toMap(model, 'id'));
export const selectModelMetadata = createSelector(selectFeatureState, s => s.metadata);
export const selectSubModels = createSelector(selectMfmModel, model =>
  toMap(
    model.filter(({ concept }) => it(isSUB(() => concept))),
    'id',
  ),
);
export const selectModelConnections = createSelector(selectModelMap, selectConnections);
export const selectSelectedMfmModel = createSelector(
  selectSelectedIds,
  selectModelMap,
  (ids, model) => ({ ids, entities: ids.map(id => model.get(id)) }),
);

export const selectMfmModelById = (
  id: string,
): MemoizedSelector<
  AppState,
  MfmConceptConfiguration,
  DefaultProjectorFn<MfmConceptConfiguration | null>
> => createSelector(selectModelMap, map => map.get(id) ?? null);

// prettier-ignore
const selectModelAutomaticActuators = createSelector(selectModelMap, selectModelConnections, selectAutomaticActuators);
// prettier-ignore
const selectModelManualActuators = createSelector(selectModelMap, selectModelConnections, selectManualActuators);
const selectModelProvisionalActuators = createSelector(
  selectMfmModel,
  model => new Set(model.filter(x => x.actuatorType === 'provisional').map(({ id }) => id)),
);

export const selectModelActuators = createSelector(
  selectModelAutomaticActuators,
  selectModelManualActuators,
  selectModelProvisionalActuators,
  (automatic, manual, provisional) =>
    ({
      all: new Set([...automatic, ...manual, ...provisional]),
      automatic,
      manual,
      provisional,
    } as ModelActuators),
);
export const selectModelReliefSystems = createSelector(
  selectModelMap,
  selectModelConnections,
  selectModelActuators,
  selectReliefSystems,
);
export const selectModelSensors = createSelector(selectModelMap, selectSensors);
export const selectModelExposed = createSelector(
  selectMfmModel,
  model => new Set(model.filter(x => it(isExposed(x.exposed))).map(x => x.id)),
);
export const selectModelEquipment = createSelector(selectMfmModel, selectEquipment);
export const selectModelProcessVariables = createSelector(
  selectMfmModel,
  model => new Set(model.filter(x => it(isProcessVariable(x.processVariable))).map(x => x.id)),
);
export const selectModelExtended = createSelector(
  selectModelActuators,
  selectModelEquipment,
  selectModelExposed,
  selectModelMap,
  selectModelProcessVariables,
  selectModelReliefSystems,
  selectModelSensors,
  (actuators, equipment, exposed, model, processVariables, reliefSystems, sensors) => ({
    actuators,
    equipment,
    exposed,
    model,
    processVariables,
    reliefSystems,
    sensors,
  }),
);

export const selectModelFlat = (
  cells: mxgraph.mxCell[],
  defaultParentId: string = '1',
): MemoizedSelector<AppState, unknown[], DefaultProjectorFn<unknown[]>> =>
  createSelector(
    selectModelExtended,
    selectLibrary,
    selectCausalAnalysisDescriptionList,
    ({ actuators, model, reliefSystems, sensors }, library, labels) =>
      // prettier-ignore
      new FlatFileBuilder(cells, model, library, labels, actuators, reliefSystems, sensors, defaultParentId).build(),
  );

export const selectModelMetadataUpdated = (
  notes: mxgraph.mxCell[],
  viewport: { scale: number; x: number; y: number },
): MemoizedSelector<
  AppState,
  AppState['model']['metadata'],
  DefaultProjectorFn<AppState['model']['metadata']>
> =>
  createSelector(selectModelMetadata, selectModelMap, (metadata, model) => ({
    ...metadata,
    notes: notes.map(cell =>
      new ObjectBuilder()
        .use(new MfmDescriptionProvider(model.get(cell.id.toString())))
        .use(new GeometryProvider(cell, ''))
        .get(),
    ),
    workbench: {
      viewport,
    },
  }));
