// eslint-disable-next-line max-classes-per-file
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationOption } from '@workbench/core/services/confirmation.service';

export interface ConfirmationData {
  additionalInformation?: string;
  message?: string;
  options?: Partial<{
    [ConfirmationOption.Confirm]: string;
    [ConfirmationOption.Reject]: string;
  }>;
}

@Component({
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  public readonly additionalInformation: string = '';
  public readonly message: string = '';
  public readonly confirm: string = '';
  public readonly reject: string = '';

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ConfirmationData,
  ) {
    this.additionalInformation = this.data?.additionalInformation ?? '';
    this.message = this.data?.message ?? 'Please confirm';
    this.confirm = this.data?.options?.[ConfirmationOption.Confirm] ?? 'Confirm';
    this.reject = this.data?.options?.[ConfirmationOption.Reject] ?? '';
  }

  public onCancel(): void {
    this.dialogRef.close(ConfirmationOption.Cancel);
  }

  public onConfirm(): void {
    this.dialogRef.close(ConfirmationOption.Confirm);
  }

  public onReject(): void {
    this.dialogRef.close(ConfirmationOption.Reject);
  }
}
