import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { it } from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { isRelation } from '../core/mfm-core';

export class MfmRelationProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(
    private readonly source: {
      concept: ResourceConcept;
      mainFunction: { sourceId?: string; targetId?: string };
      linkSourceId: string;
      linkTargetId: string;
    },
  ) {
    super();
  }

  public get(): MfmConcept | undefined {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(isRelation(concept))) {
      return {
        end: getNotEmpty(this.source.mainFunction?.targetId, this.source.linkTargetId),
        start: getNotEmpty(this.source.mainFunction?.sourceId, this.source.linkSourceId),
      } as MfmConcept;
    }
  }
}
