import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { SeverityLevel } from '@workbench/common/enums/severity-level.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { it } from '@workbench/common/utils/logical-utility';
import { isHAZ } from '../core/mfm-core';

export class HazardLevelProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; level: SeverityLevel | '' }) {
    super();
  }

  public get(): MfmConcept | undefined {
    if (it(isHAZ(() => this.source.concept))) {
      return {
        level: this.source.level ?? SeverityLevel.High,
      } as MfmConcept;
    }
  }
}
