import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { ProcessVariableValue } from '@workbench/common/types/process-variable-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { hasAttribute } from '../core/mfm-attributes';

interface MfmGroupsProviderData {
  groups: Partial<{
    actuatorTag: string;
    componentLabel: string;
    componentTag: string;
    equipment: string;
    instrumentTag: string;
    partLabel: string;
    partTag: string;
    pipingInstrumentationDiagram: string;
    subUnitLabel: string;
    subUnitTag: string;
    systemLabel: string;
    systemTag: string;
    tag: string;
  }>;
}

export class MfmGroupsProvider extends ObjectBuilderProvider<MfmGroupsProviderData> {
  constructor(
    private readonly source: {
      actuatorTag: string;
      concept: ResourceConcept;
      equipment: string;
      instrumentTag: string;
      levelComponentLabel: string;
      levelComponentTag: string;
      levelPartLabel: string;
      levelPartTag: string;
      levelSubUnitLabel: string;
      levelSubUnitTag: string;
      pipingInstrumentationDiagram: string;
      processVariable: ProcessVariableValue;
      systemLabel: string;
      systemTag: string;
      tag: string;
    },
  ) {
    super();
  }

  public get(): MfmGroupsProviderData | undefined {
    const groups = Object.assign(
      Object.create(null),

      it(hasAttribute(this.source.concept, 'equipment'))
        ? { equipment: this.source.equipment }
        : {},

      // TODO: The condition that distinguishes whether the item
      // has an 'instrumentTag' or not should be moved to the appropriate place,
      // as it is used in several places.
      it(
        hasAttribute(this.source.concept, 'instrumentTag', {
          processVariable: this.source.processVariable,
        }),
      )
        ? { instrumentTag: this.source.instrumentTag }
        : {},

      it(hasAttribute(this.source.concept, 'levelComponentLabel'))
        ? { componentLabel: this.source.levelComponentLabel }
        : {},

      it(hasAttribute(this.source.concept, 'levelComponentTag'))
        ? { componentTag: this.source.levelComponentTag }
        : {},

      it(hasAttribute(this.source.concept, 'levelPartLabel'))
        ? { partLabel: this.source.levelPartLabel }
        : {},

      it(hasAttribute(this.source.concept, 'levelPartTag'))
        ? { partTag: this.source.levelPartTag }
        : {},

      it(hasAttribute(this.source.concept, 'levelSubUnitLabel'))
        ? { subUnitLabel: this.source.levelSubUnitLabel }
        : {},

      it(hasAttribute(this.source.concept, 'levelSubUnitTag'))
        ? { subUnitTag: this.source.levelSubUnitTag }
        : {},

      it(hasAttribute(this.source.concept, 'pipingInstrumentationDiagram'))
        ? { pipingInstrumentationDiagram: this.source.pipingInstrumentationDiagram }
        : {},

      it(hasAttribute(this.source.concept, 'systemLabel'))
        ? { systemLabel: this.source.systemLabel ?? '' }
        : {},

      it(hasAttribute(this.source.concept, 'systemTag'))
        ? { systemTag: this.source.systemTag ?? '' }
        : {},

      it(hasAttribute(this.source.concept, 'tag')) ? { tag: this.source.tag } : {},
    );

    if (Object.keys(groups).length > 0) {
      return { groups };
    }
  }
}
