export function difference<T>(a: Set<T>, b: Set<T>): T[] {
  return Array.from(a).filter(x => b.has(x) === false);
}

export function intersection<T>(a: Set<T>, b: Set<T>): T[] {
  return Array.from(a).filter(x => b.has(x));
}

export function sorted<T>(a: Set<T>, compareFn?: (a: T, b: T) => number): Set<T> {
  return new Set(Array.from(a).sort(compareFn));
}

export function symDifference<T>(a: Set<T>, b: Set<T>): T[] {
  return [...difference(a, b), ...difference(b, a)];
}

export function union<T>(a: Set<T>, b: Set<T>, ...[c, ...rest]: Set<T>[]): T[] {
  return c != null ? union(new Set([...a, ...b]), c, ...rest) : Array.from(new Set([...a, ...b]));
}
