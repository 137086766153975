// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { OutputStrategy } from './output-strategy';

export const environment = {
  appConfig: 'app-config.json',
  output: OutputStrategy.File, // Use OutputStrategy.Console to output into the console
  production: false,
  version: require('../../package.json').version,
};
