import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wb-card',
  styleUrls: ['./card.component.scss'],
  templateUrl: './card.component.html',
})
export class CardComponent implements OnInit {
  @Input() public icon: string | undefined;
  @Input() public titleText = '';
  @Input() public badge = '';

  constructor() {}

  public ngOnInit(): void {}
}
