import { equal, idt, or, Predicate } from '../utils/logical-utility';

export type ProcessVariableValue = 'online' | 'offline' | 'virtual' | false;

export const processVariableValue = (value: string | number): ProcessVariableValue => {
  // prettier-ignore
  switch ((value ?? '').toString().toLowerCase()) {
    case '1': case 'online': return 'online';
    case '2': case 'offline': return 'offline';
    case '3': case 'virtual': return 'virtual';
    default: return false;
  }
};

export const isProcessVariable = (value: ProcessVariableValue): Predicate =>
  or(
    isOfflineProcessVariable(value),
    isOnlineProcessVariable(value),
    isVirtualProcessVariable(value),
  );
export const isOfflineProcessVariable = (value: ProcessVariableValue): Predicate =>
  equal(idt(value), offlineProcessVariable);
export const isOnlineProcessVariable = (value: ProcessVariableValue): Predicate =>
  equal(idt(value), onlineProcessVariable);
export const isVirtualProcessVariable = (value: ProcessVariableValue): Predicate =>
  equal(idt(value), virtualProcessVariable);

const offlineProcessVariable = (): ProcessVariableValue => 'offline';
const onlineProcessVariable = (): ProcessVariableValue => 'online';
const virtualProcessVariable = (): ProcessVariableValue => 'virtual';
