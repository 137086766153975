import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it } from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { isRelation } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { MfmConceptConfiguration } from '../mfm-model.model';

interface Connection {
  edge: { id: string; concept: ResourceConcept };
  target?: { id: string; concept: ResourceConcept };
  source?: { id: string; concept: ResourceConcept };
}

export function selectConnections(
  model: Map<string, MfmConceptConfiguration>,
): Map<string, Connection[]> {
  return Array.from(model.values()).reduce((map, item) => {
    if (it(isRelation(() => item.concept))) {
      const sourceId = getNotEmpty(item.mainFunction?.sourceId, item.linkSourceId);
      const targetId = getNotEmpty(item.mainFunction?.targetId, item.linkTargetId);

      if (sourceId !== '' && targetId !== '') {
        if (map.has(sourceId) === false) {
          map.set(sourceId, []);
        }
        if (map.has(targetId) === false) {
          map.set(targetId, []);
        }

        const source = model.get(sourceId);
        const target = model.get(targetId);

        map.set(
          sourceId,
          map.get(sourceId).concat({
            target: { id: targetId, concept: target.concept },
            edge: { id: item.id, concept: item.concept },
          }),
        );
        map.set(
          targetId,
          map.get(targetId).concat({
            source: { id: sourceId, concept: source.concept },
            edge: { id: item.id, concept: item.concept },
          }),
        );
      }
    }

    return map;
  }, new Map<string, Connection[]>());
}
