import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface AppConfig {
  azureAdClientId: string;
  azureAdDomain: string;
  azureAdScopes: string[];
  azureAdSignUpSignInPolicyId: string;
  appBaseUrl: string;
  cmrsBaseUrl: string;
  haBaseUrl: string;
  modelApiBaseUrl: string;
  portalApiBaseUrl: string;
  portalBaseUrl: string;
}

export const configFactory = (configService: AppConfigService) => (): Promise<boolean> =>
  configService.initialize();

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly httpClient: HttpClient;
  private appConfig: AppConfig;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public initialize(): Promise<boolean> {
    return firstValueFrom(
      this.httpClient.get<AppConfig>('./assets/app-config.json').pipe(
        tap((config: AppConfig) => this.setConfig(config)),
        map(() => true),
      ),
    );
  }

  public getConfig(): AppConfig {
    return this.appConfig;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setConfig(config: any): void {
    // Check and throw an error if config.json contains incorrect keys.
    // There is no type-safe between json and interface describes its structure,
    // so we have to check it manually for typos and mistakes
    Object.keys(config).forEach(key => {
      switch (key) {
        case 'azureAdClientId':
        case 'azureAdDomain':
        case 'azureAdScopes':
        case 'azureAdSignUpSignInPolicyId':
        case 'appBaseUrl':
        case 'cmrsBaseUrl':
        case 'haBaseUrl':
        case 'modelApiBaseUrl':
        case 'portalApiBaseUrl':
        case 'portalBaseUrl':
          break;
        default:
          console.error(`Config has an unexpected field ${key}`);
      }
    });
    this.appConfig = {
      appBaseUrl: config.appBaseUrl,
      azureAdClientId: config.azureAdClientId,
      azureAdDomain: config.azureAdDomain,
      azureAdScopes: config.azureAdScopes.split(',') as string[],
      azureAdSignUpSignInPolicyId: config.azureAdSignUpSignInPolicyId,
      cmrsBaseUrl: config.cmrsBaseUrl,
      haBaseUrl: config.haBaseUrl,
      modelApiBaseUrl: config.modelApiBaseUrl,
      portalApiBaseUrl: config.portalApiBaseUrl,
      portalBaseUrl: config.portalBaseUrl,
    };
  }
}
