import { or, Predicate } from '../utils/logical-utility';

// Input and Output are specific values for the Barrier, Store, and Transport concepts.
export type ExposureState = true | false | 'input' | 'output';

export const isExposed = (value: ExposureState): Predicate =>
  or(
    () => value === true,
    () => value === 'input',
    () => value === 'output',
  );
