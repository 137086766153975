import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { it, or } from '@workbench/common/utils/logical-utility';
import { isFunction, isObjective, isStructure } from '../core/mfm-core';

export class MfmParentProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; parentId: string }) {
    super();
  }

  public get(): MfmConcept {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(or(isStructure(concept), isFunction(concept), isObjective(concept)))) {
      return {
        parent: this.source.parentId ?? '',
      } as MfmConcept;
    }
  }
}
