// There is no other ResourceConcept, but eslint highlights it as already declared
export enum ResourceConcept {
  Undef = 'undef',
  // TODO: Remove SYS and MEQ when we finally rid of System and Equipment structures
  Sys = 'sys', // System
  Meq = 'meq', // Main Equipment
  Mfs = 'mfs',
  Efs = 'efs',
  Cfs = 'cfs',
  Sou = 'sou',
  Sin = 'sin',
  Tra = 'tra',
  Bar = 'bar',
  Sto = 'sto',
  Bal = 'bal',
  Pco = 'pco',
  Dco = 'dco',
  Mco = 'mco',
  Sup = 'sup',
  Tar = 'tar',
  Haz = 'haz',
  Pa = 'pa',
  In = 'in',
  Ac = 'ac',
  En = 'en',
  Di = 'di',
  Pr = 'pr',
  Ma = 'ma',
  De = 'de',
  Su = 'su',
  Me = 'me',
  Pp = 'pp',
  Ip = 'ip',
  TraD = 'tra-d',
  TraU = 'tra-u',
  BarD = 'bar-d',
  BarU = 'bar-u',
  And = 'and',
  Vor = 'vor', // Voting-OR logical gate
  Sha = 'sha', // Sharing relation
  Sub = 'sub', // Sub-model
  Txt = 'txt', // Text note
}
