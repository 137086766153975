import { Unique } from '../types/unique.type';

/**
 * Checks if a value is defined.
 *
 * @template T The type of the value.
 * @param value The value to check.
 * @param index The index of the value in the array.
 * @param array The array containing the value.
 * @returns True if the value is defined, false otherwise.
 */
export const isDefined = <T>(value: T, index: number, array: T[]): boolean =>
  (value ?? null) !== null;

/**
 * Converts an array to a Map object with the specified key and value selectors.
 *
 * @param source The source array to convert to a Map object.
 * @param key The key selector for the Map object. Can be a string representing the property name or a function that returns the key.
 * @param value The optional value selector for the Map object. Can be a function that returns the value or undefined to use the original item as the value.
 * @returns A Map object with the specified key-value pairs.
 */
// prettier-ignore
export function toMap<T>(source: T[], key: ((item: T) => string) | (keyof T & string)): Map<string, T>;
// prettier-ignore
export function toMap<T, R>(source: T[], key: keyof T & string, value: (item: T) => R): Map<string, R>;
// prettier-ignore
export function toMap<T, R>(source: T[], key: (keyof T & string) | ((item: T) => string), value?: (item: T) => R): Map<string, T | R> {
  return new Map(
    source.map(x => [String(typeof key === 'function' ? key(x) : x[key]), value?.(x) ?? x]),
  );
}

/**
 * Returns a new array with unique items from the source array,
 * each item is assigned a unique identifier `uid` based on its index in the array.
 *
 * @param source The source array to uniquify.
 * @returns A new array with unique items from the source array, each item is assigned a unique identifier.
 */
export function uniquify<T>(source: T[]): Array<T & Unique<number>> {
  return source.map((v, index) => ({ ...v, uid: index }));
}
