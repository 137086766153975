import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import {
  isOnlineProcessVariable,
  isVirtualProcessVariable,
  ProcessVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { equal, it, not } from '@workbench/common/utils/logical-utility';

export class ProcessVariableProvider extends ObjectBuilderProvider<unknown> {
  constructor(
    private readonly source: {
      processVariable: ProcessVariableValue;
      expression: string;
    },
  ) {
    super();
  }

  public get():
    | {
        processVariable: ProcessVariableValue;
        expression?: string;
      }
    | undefined {
    if (
      it(
        not(
          equal(
            () => this.source.processVariable,
            () => false,
          ),
        ),
      )
    ) {
      if (it(isVirtualProcessVariable(this.source.processVariable))) {
        return {
          processVariable: this.source.processVariable,
          expression: this.source.expression,
        };
      }

      if (it(isOnlineProcessVariable(this.source.processVariable))) {
        return {
          processVariable: this.source.processVariable,
        };
      }

      return {
        processVariable: this.source.processVariable,
      };
    }
  }
}
