{
  "name": "workbench",
  "version": "1.10.10-dev.11",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "analyze": "webpack-bundle-analyzer dist/stats.json",
    "build": "ng build --configuration=prod",
    "build:dev": "ng build --configuration=dev",
    "build:test": "ng build --configuration=test",
    "build:feature": "ng build --configuration=feature",
    "build:stats": "ng build --stats-json --configuration=dev",
    "test": "ng test",
    "test:once": "ng test --no-watch --no-progress --source-map=false --browsers=ChromeHeadlessCI",
    "test:silent": "ng test --no-progress --source-map=false --browsers=ChromeHeadlessCI",
    "pree2e": "webdriver-manager update --standalone false --gecko false",
    "e2e": "protractor",
    "lint": "eslint . --ext .ts",
    "lint:fix": "eslint . --fix --ext .ts"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.4",
    "@angular/cdk": "^17.0.1",
    "@angular/common": "^17.0.4",
    "@angular/compiler": "^17.0.4",
    "@angular/core": "^17.0.4",
    "@angular/forms": "^17.0.4",
    "@angular/material": "^17.0.1",
    "@angular/platform-browser": "^17.0.4",
    "@angular/platform-browser-dynamic": "^17.0.4",
    "@angular/router": "^17.0.4",
    "@azure/msal-angular": "^3.0.8",
    "@azure/msal-browser": "^3.5.0",
    "@microsoft/signalr": "^6.0.7",
    "@ngrx/component-store": "^17.0.0",
    "@ngrx/effects": "^17.0.0",
    "@ngrx/entity": "^17.0.0",
    "@ngrx/store": "^17.0.0",
    "@ngrx/store-devtools": "^17.0.0",
    "bootstrap": "^5.3.2",
    "core-js": "^3.34.0",
    "fast-json-patch": "^3.1.1",
    "font-awesome": "^4.7.0",
    "immer": "^9.0.7",
    "json2typescript": "^1.4.1",
    "mxgraph": "^4.2.0",
    "rxjs": "^7.8.0",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1700.3",
    "@angular-devkit/build-angular": "^17.0.3",
    "@angular-eslint/builder": "^17.1.0",
    "@angular-eslint/eslint-plugin": "1.1.0",
    "@angular-eslint/eslint-plugin-template": "1.1.0",
    "@angular-eslint/template-parser": "1.1.0",
    "@angular/cli": "^17.0.3",
    "@angular/compiler-cli": "^17.0.4",
    "@ngrx/schematics": "^17.0.0",
    "@types/jasmine": "^3.10.2",
    "@types/node": "^12.20.37",
    "@typescript-eslint/eslint-plugin": "^5.57.0",
    "@typescript-eslint/parser": "^5.57.0",
    "eslint": "^7.6.0",
    "eslint-config-prettier": "^7.2.0",
    "eslint-plugin-html": "^6.2.0",
    "eslint-plugin-import": "2.22.1",
    "eslint-plugin-jsdoc": "30.7.6",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "eslint-plugin-prettier": "^3.4.1",
    "husky": "^4.3.8",
    "jasmine-core": "^3.10.1",
    "jasmine-spec-reporter": "^4.2.1",
    "karma": "^6.4.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-cli": "^2.0.0",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "^2.0.1",
    "karma-jasmine-html-reporter": "^1.4.0",
    "prettier": "^2.5.1",
    "pretty-quick": "^3.1.2",
    "protractor": "^7.0.0",
    "ts-node": "^8.3.0",
    "typescript": "^5.2.2",
    "webpack-bundle-analyzer": "^4.8.0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged"
    }
  }
}
