@if (tasks.length > 0) {
<div class="task-monitor__list">
    @for (task of tasks.slice(0, 5); track $index) {
    @if (task[1].length > 0) {
    <div class="mat-caption task-monitor__task"> {{ task[1] }} </div>
    }
    }
    @if (tasks.length > 5) {
    <div class="mat-caption task-monitor__task"> ... </div>
    }
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
}
