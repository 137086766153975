import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { CategoryValue } from '@workbench/common/types/category-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { isObjective } from '../core/mfm-core';

export class ObjectiveProvider extends ObjectBuilderProvider<unknown> {
  constructor(private readonly source: { concept: ResourceConcept; category: CategoryValue | '' }) {
    super();
  }

  public get(): { category: CategoryValue | '' } | undefined {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(isObjective(concept))) {
      return {
        category: this.source.category ?? '',
      };
    }
  }
}
