import { it, or } from '@workbench/common/utils/logical-utility';

import { SeverityLevel } from '@workbench/common/enums/severity-level.enum';
import { union } from '@workbench/common/utils/set-util';
import {
  isBAR,
  isDCO,
  isDE,
  isHAZ,
  isSTO,
} from '@workbench/multilevel-flow-modeling/core/mfm-core';
import {
  selectModelActuators,
  selectModelConnections,
  selectModelMap,
} from './model-builder.selectors';

/**
 * The logic of recognition of the Relief System storage.
 * It follows from the Actuators recognition mechanism and is based on it.
 *
 * A Relief System consists of a Destroy control loop from storage (the protected storage)
 * to an actuated barrier (the Safety Valve providing the relief).
 * There are two versions, (a) Overpressure protection: high state in the protected storage,
 * where the barrier leads away from the storage, (b) Vacuum protection: low state in the protected storage,
 * where the barrier leads into the storage.
 *
 * The pattern should be Storage - Destroy relation - Hazard - Destroy control - Barrier - Barrier.
 * It consists of an Actuator loop (one of the states) with connected Storage with a Destroy relation.
 *
 * @param model an MFM model
 * @param connections a Map of connections
 * @returns a Set of IDs found Relief Systems
 */
export function selectReliefSystems(
  model: ReturnType<typeof selectModelMap>,
  connections: ReturnType<typeof selectModelConnections>,
  { automatic, manual }: ReturnType<typeof selectModelActuators>,
): Map<string, string> {
  const result = new Map<string, string>();

  Array.from(union(automatic, manual))
    .filter(id => it(isBAR(() => model.get(id).concept)))
    .forEach(actuator => {
      connections
        .get(actuator)
        .filter(({ source }) => it(or(isBAR(() => source?.concept))))
        .map(({ source }) => source?.id)
        .flatMap(id =>
          connections
            .get(id)
            .filter(({ source }) => it(or(isDCO(() => source?.concept))))
            .map(({ source }) => source?.id),
        )
        .flatMap(id =>
          connections
            .get(id)
            .filter(({ source }) =>
              it(
                isHAZ(() => source?.concept),
                or(
                  () => model.get(source?.id).level === SeverityLevel.High,
                  () => model.get(source?.id).level === SeverityLevel.Low,
                ),
              ),
            )
            .map(({ source, target }) => source?.id ?? target?.id),
        )
        .flatMap(id =>
          connections
            .get(id)
            .filter(({ edge, source }) =>
              it(
                isDE(() => edge.concept),
                isSTO(() => source?.concept),
              ),
            )
            .map(({ source }) => source?.id),
        )
        .forEach(x => {
          result.set(x, actuator);
        });
    });

  return result;
}
