import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { it } from '@workbench/common/utils/logical-utility';
import { isVOR } from '../core/mfm-core';

export class LogicGateVotesProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; logicGateVotes: number }) {
    super();
  }

  public get(): MfmConcept | undefined {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(isVOR(concept))) {
      return {
        //parseInt(this.source.logicGateVotes?.toString(), 10)
        n: this.source.logicGateVotes ?? 0,
      } as MfmConcept;
    }
  }
}
