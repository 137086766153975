import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it } from '@workbench/common/utils/logical-utility';
import { hasAttribute } from '../core/mfm-attributes';

export class MfmDescriptionProvider extends ObjectBuilderProvider<{
  comment: string;
  description?: string;
  label?: string;
}> {
  constructor(
    private readonly source: {
      label: string;
      description: string;
      comment: string;
      concept: ResourceConcept;
    },
  ) {
    super();
  }

  public get(): { comment: string; description?: string; label?: string } | undefined {
    const result = {
      comment: this.source.comment,
    };

    if (it(hasAttribute(this.source.concept, 'description'))) {
      Object.assign(result, { description: this.source.description });
    }
    if (it(hasAttribute(this.source.concept, 'label'))) {
      Object.assign(result, { label: this.source.label });
    }

    return result;
  }
}
