import { ObjectBuilder } from '@workbench/common/converter/object-builder';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { ExposeProvider } from './expose-provider';
import { HazardLevelProvider } from './hazard-level-provider';
import { InfluenceRelationProvider } from './influence-relation-provider';
import { LogicGateVotesProvider } from './logic-gate-votes-provider';
import { MfmBaseProvider } from './mfm-base-provider';
import { MfmParentProvider } from './mfm-parent-provider';
import { MfmRelationProvider } from './mfm-relation-provider';

export class ModelBuilder {
  constructor(private readonly mfm: MfmConceptConfiguration[]) {}

  public build(): MfmConcept[] {
    return this.mfm.map(mfm =>
      new ObjectBuilder<MfmConcept>()
        .use(new MfmBaseProvider(mfm))
        .use(new MfmParentProvider(mfm))
        .use(new MfmRelationProvider(mfm))
        .use(new InfluenceRelationProvider(mfm))
        .use(new HazardLevelProvider(mfm))
        .use(new LogicGateVotesProvider(mfm))
        .use(new ExposeProvider(mfm))
        .get(),
    );
  }
}
