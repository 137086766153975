import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationComponent,
  ConfirmationData,
} from '@workbench/shared/confirmation/confirmation.component';
import { Observable } from 'rxjs';

export enum ConfirmationOption {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Reject = 'reject',
}

@Injectable()
export class ConfirmationService {
  constructor(private readonly matDialog: MatDialog) {}

  public confirm(config?: ConfirmationData): Observable<ConfirmationOption> {
    const dialogRef = this.matDialog.open(ConfirmationComponent, {
      maxWidth: '450px',
      data: config,
    });

    return dialogRef.afterClosed();
  }
}
