import { IdSelectorStr } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { toMap } from '@workbench/common/utils/array-util';
import { adapter, LibraryModel, LibraryState } from '.';

export { selectLibrary };

const selectFeatureState = createFeatureSelector<LibraryState>('library');
const selectLibraryModels = createSelector(selectFeatureState, adapter.getSelectors().selectAll);
const selectLibrary = createSelector(selectLibraryModels, x =>
  toMap(x, adapter.selectId as IdSelectorStr<LibraryModel>),
);
