/* eslint-disable @typescript-eslint/member-ordering */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Option } from '@workbench/common/models/option';
import { map, Observable, startWith, Subject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: MultiSelectComponent }],
  selector: 'wb-multi-select',
  styleUrls: ['./multi-select.component.scss'],
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent
  implements MatFormFieldControl<Option['id'][]>, ControlValueAccessor, OnChanges, OnDestroy
{
  private static nextId = 0;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') public userAriaDescribedBy?: string;
  @Input() public options: Option[] = [];
  @Input() public selected: Option['id'][] = [];

  @HostBinding() public readonly id = `wb-multi-select-${MultiSelectComponent.nextId++}`;
  @HostBinding('attr.aria-labelledby') public readonly ariaLabelledBy =
    this.formField?.getLabelId();

  @ViewChild('select') private readonly selectElement: MatSelect;

  public readonly autofilled?: boolean;
  public readonly controlType = 'wb-multi-select';
  public readonly filteredOptions$: Observable<Option[]>;
  public focused = false;
  public readonly selectControl = new FormControl([], { updateOn: 'change' });
  public readonly stateChanges = new Subject<void>();
  public touched = false;

  private readonly filterSource$ = new Subject<string>();

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled === true) {
      this.selectControl.disable({ emitEvent: false });
    }
    if (this._disabled === false) {
      this.selectControl.enable({ emitEvent: false });
    }
    this.stateChanges.next();
  }

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value ?? '';
    this.stateChanges.next();
  }

  @Input()
  public get readonly(): boolean {
    return this._readonly;
  }
  public set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
    if (this._readonly === true) {
      this.selectControl.disable();
    }
    if (this._readonly === false) {
      this.selectControl.enable();
    }
    this.stateChanges.next();
  }

  @Input()
  public get required(): boolean {
    return this._required;
  }
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  public get value(): Option['id'][] {
    // Filter out the 'Select All' item
    return this._value.filter(id => id !== '-1');
  }
  public set value(value: Option['id'][]) {
    this._value = value;
    this.selectControl.setValue(this._value, { emitEvent: false });
    this.stateChanges.next();
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public readonly ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.filteredOptions$ = this.filterSource$.pipe(
      startWith(''),
      map(str => str.toLowerCase()),
      map(str =>
        this.options.filter(({ label }) => str.trim() === '' || label.toLowerCase().includes(str)),
      ),
    );
    this.selectControl.valueChanges
      .pipe<string[]>(map(selection => selection.filter(id => id !== '-1')))
      .subscribe(selection => {
        this.onChange(selection);
      });
  }

  public get empty(): boolean {
    return this.selectControl.value.length === 0;
  }

  public get errorState(): boolean {
    return this.selectControl.invalid === true && this.touched === true;
  }

  public get shouldLabelFloat(): boolean {
    return this.focused === true || this.empty === false;
  }

  /* eslint-disable */
  private _disabled = false;
  private _placeholder = '';
  private _required = false;
  private _readonly = false;
  private _value: Option['id'][] = [];
  /* eslint-enable */

  public displayFn(): string {
    return '';
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  public onContainerClick(event: MouseEvent): void {
    this.selectElement.open();
  }

  public onChange = (value: string[]): void => {};

  public onFocusIn(event: FocusEvent): void {
    if (this.focused === false) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  public onFocusOut(event: FocusEvent): void {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.value = changes.selected.currentValue;
    }
    if (changes.options) {
      this.options = changes.options.currentValue ?? [];
      this.writeValue(this.value);
    }
    this.stateChanges.next();
  }

  public onSelectAll(event: MatOptionSelectionChange): void {
    if (event.isUserInput === true) {
      if (event.source.selected === true) {
        this.onChange(this.options.map(({ id }) => id));
      }
      if (event.source.selected === false) {
        this.onChange([]);
      }
    }
  }

  public onTouched = (): void => {};

  public registerOnChange(fn: (value: Option['id'][]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setDescribedByIds(ids: string[]): void {
    this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  public writeValue(value: Option['id'][]): void {
    const allSelected =
      value?.length > 0 && this.options?.length > 0 && value.length === this.options.length;

    this.value = allSelected ? ['-1', ...value] : value;
  }
}
