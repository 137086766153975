import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { it, or } from '@workbench/common/utils/logical-utility';
import { isAND, isFunction, isObjective, isRelation, isStructure, isVOR } from '../core/mfm-core';

export class MfmTypeProvider extends ObjectBuilderProvider<{
  type: 'function' | 'objective' | 'relation' | 'structure';
}> {
  constructor(private readonly source: { concept: ResourceConcept }) {
    super();
  }

  public get(): { type: 'function' | 'objective' | 'relation' | 'structure' } {
    return {
      type: this.getType(),
    };
  }

  private readonly concept = (): ResourceConcept => this.source.concept;

  private getType(): 'function' | 'objective' | 'relation' | 'structure' {
    // VOR and AND is considered as Objective here
    if (it(or(isVOR(this.concept), isAND(this.concept)))) {
      return 'objective';
    }
    if (it(isObjective(this.concept))) {
      return 'objective';
    }
    if (it(isFunction(this.concept))) {
      return 'function';
    }
    if (it(isRelation(this.concept))) {
      return 'relation';
    }
    if (it(isStructure(this.concept))) {
      return 'structure';
    }
  }
}
