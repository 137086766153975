import { Pipe, PipeTransform } from '@angular/core';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';

@Pipe({
  name: 'conceptIcon',
})
export class ConceptIconPipe implements PipeTransform {
  public transform(value: ResourceConcept): string | undefined {
    // prettier-ignore
    switch (value) {
      case ResourceConcept.Bal: return 'wb-mfm-bal';
      case ResourceConcept.Bar: return 'wb-mfm-bar';
      case ResourceConcept.Dco: return 'wb-mfm-dco';
      case ResourceConcept.Haz: return 'wb-mfm-haz';
      case ResourceConcept.Mco: return 'wb-mfm-mco';
      case ResourceConcept.Pco: return 'wb-mfm-pco';
      case ResourceConcept.Sin: return 'wb-mfm-sin';
      case ResourceConcept.Sou: return 'wb-mfm-sou';
      case ResourceConcept.Sup: return 'wb-mfm-sup';
      case ResourceConcept.Sto: return 'wb-mfm-sto';
      case ResourceConcept.Tar: return 'wb-mfm-tar';
      case ResourceConcept.Tra: return 'wb-mfm-tra';
      case ResourceConcept.Undef: return 'wb-mfm-undef';
      // eslint-disable-next-line no-undefined
      default: return undefined;
    }
  }
}
