<div class="notification">
    <header class="notification__header">
        <h2 class="notification__title"> {{ data.title }} </h2>
        @if (data.numOfMessages > 1) {
        <strong class="notification__counter"> 1 of {{ data.numOfMessages }} </strong>
        }
        @if (timer$ !== null) {
        <mat-progress-spinner
            [value]="timer$ | async"
            diameter="16"
        ></mat-progress-spinner>
        }
    </header>
    @for (line of data.message.split('\n'); track $index) {
    <div class="notification__message-line">
        @for (token of line.split('\t'); track $index) {
        <span>{{token}}</span>
        @if ($last === false) {
        <span class="like-a-tabulation"></span>
        }
        }
    </div>
    }
    <button
        (click)="onActionClick()"
        class="notification__action"
        color="secondary"
        mat-button
    > Close </button>
</div>
