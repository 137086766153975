<form [formGroup]="form">
    <mat-form-field
        appearance="outline"
        class="option-and-description-editor__form-field"
        color="primary"
    >
        <mat-label> {{ optionsLabel }} </mat-label>
        <mat-select
            formControlName="option"
            [placeholder]="optionsPlaceholder"
            required
        >
            <mat-option
                *ngFor="let option of options"
                [value]="option.id"
            >
                {{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field
        appearance="outline"
        class="option-and-description-editor__form-field"
        color="primary"
    >
        <mat-label> {{ descriptionLabel }} </mat-label>
        <input
            [placeholder]="descriptionPlaceholder"
            formControlName="description"
            matInput
            required
            type="text"
        />
        <button
            (click)="onClearDescriptionClick()"
            aria-label="Clear"
            matSuffix
            mat-icon-button
            type="button"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>

<div class="option-and-description-editor__actions">
    <button
        (click)="onDiscard()"
        mat-button
    > Discard </button>
    <button
        [disabled]="form.invalid"
        (click)="onSubmit()"
        mat-button
        color="primary"
    > Submit </button>
</div>
