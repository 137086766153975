import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import {
  and,
  contain,
  emptyStr,
  equal,
  exist,
  idt,
  it,
  not,
  or,
  Predicate,
} from '@workbench/common/utils/logical-utility';
import { getNotEmpty } from '@workbench/common/utils/string-util';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { isRelation } from './mfm-core';

type ID = string;

export function getOppositeEnd(concept: MfmConceptConfiguration, id: ID): ID {
  if (it(equal(() => edgeSourceId(concept), idt(id)))) {
    return getNotEmpty(concept.mainFunction?.targetId, concept.linkTargetId);
  }
  if (it(equal(() => edgeTargetId(concept), idt(id)))) {
    return getNotEmpty(concept.mainFunction?.sourceId, concept.linkSourceId);
  }
}

export function hasOppositeDirection(edge: MfmConceptConfiguration, sourceId: ID): Predicate {
  return equal(() => edgeTargetId(edge), idt(sourceId));
}

export function isConnected(edge: MfmConceptConfiguration, a: ID, b?: ID): Predicate {
  return and(
    contain(
      idt(a),
      () => edgeSourceId(edge),
      () => edgeTargetId(edge),
    ),
    or(
      not(exist(idt(b))),
      contain(
        () => b,
        () => edgeSourceId(edge),
        () => edgeTargetId(edge),
      ),
    ),
  );
}

export function edgeSourceId(edge: MfmConceptConfiguration): ID {
  return getNotEmpty(edge.mainFunction?.sourceId, edge.linkSourceId);
}

export function edgeTargetId(edge: MfmConceptConfiguration): ID {
  return getNotEmpty(edge.mainFunction?.targetId, edge.linkTargetId);
}

/**
 * Find all MFM concepts that are connected with the target vertex.
 * There is an ability to set some restrictions on the type of relation,
 * on the type of MFM concept, and on the type of how it's attached
 *
 * @param id an identifier of target vertex
 * @param model a MFM model
 * @param type a type of connection. It's attached to an edge as a source, target, or both
 * @param relations a Set of allowable MFM relations (all by default)
 * @param concepts a Set of allowable MFM concepts (all by default)
 * @returns an array of connected MFM concepts
 */
export function getConnectedVertices(
  id: string,
  model: Map<string, MfmConceptConfiguration>,
  type: 'source' | 'target' | 'all' = 'all',
  relations: Set<ResourceConcept> = new Set(),
  concepts: Set<ResourceConcept> = new Set(),
): MfmConceptConfiguration[] {
  return Array.from(model.values())
    .filter(x =>
      it(
        isRelation(() => x.concept),
        or(
          () => relations.size === 0,
          () => relations.has(x.concept),
        ),
        not(
          equal(() => edgeSourceId(x), emptyStr),
          equal(() => edgeTargetId(x), emptyStr),
        ),
        contain(
          idt(id),
          () => edgeSourceId(x),
          () => edgeTargetId(x),
        ),
      ),
    )
    .reduce((acc, x) => {
      const source = model.get(edgeSourceId(x));
      const target = model.get(edgeTargetId(x));

      if (type === 'source' && source.id !== id) {
        return acc.concat(source);
      }
      if (type === 'target' && target.id !== id) {
        return acc.concat(target);
      }

      return source.id === id ? acc.concat(target) : acc.concat(source);
    }, [])
    .filter(x =>
      it(
        or(
          () => concepts.size === 0,
          () => concepts.has(x.concept),
        ),
      ),
    );
}
