export enum ReasoningFaultKind {
  BreachDs = 'breachDs',
  BreachUs = 'breachUs',
  Breach = 'breach',
  False = 'false',
  High = 'high',
  Leaking = 'leaking',
  Low = 'low',
  None = 'none',
  Normal = 'normal',
  Sourcing = 'sourcing',
  True = 'true',
}
