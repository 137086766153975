/* eslint-disable max-classes-per-file */
import { ObjectBuilder } from '@workbench/common/converter/object-builder';
import { SensorStateValue } from '@workbench/common/types/sensor-state.type';
import { toMap } from '@workbench/common/utils/array-util';
import { it, not } from '@workbench/common/utils/logical-utility';
import { mxgraph } from '@workbench/dts/mxg';
import { LibraryModel } from '@workbench/state/library';
import { CausalAnalysisItemDescription } from '@workbench/state/model-builder/causal-analysis-item-description.model';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import { ModelActuators } from '@workbench/state/model-builder/selectors/model-builder.selectors';
import { isSUB, isTXT } from '../core/mfm-core';
import { ActuatorProvider } from './actuator-provider';
import { CausalAnalysisDescriptionProvider } from './causal-analysis-description-provider';
import { ExposeProvider } from './expose-provider';
import { ExtrasProvider } from './extras-provider';
import { GeometryProvider } from './geometry-provider';
import { HazardLevelProvider } from './hazard-level-provider';
import { InfluenceRelationProvider } from './influence-relation-provider';
import { LogicGateVotesProvider } from './logic-gate-votes-provider';
import { MfmBaseProvider } from './mfm-base-provider';
import { MfmDescriptionProvider } from './mfm-description-provider';
import { MfmGroupsProvider } from './mfm-groups-provider';
import { MfmParentProvider } from './mfm-parent-provider';
import { MfmRelationProvider } from './mfm-relation-provider';
import { MfmTypeProvider } from './mfm-type-provider';
import { ObjectRoleProvider } from './object-role-provider';
import { ObjectiveProvider } from './objective-provider';
import { ProcessVariableProvider } from './process-variable-provider';
import { ReliefSystemProvider } from './relief-system-provider';
import { SensorStateProvider } from './sensor-state-provider';
import { SubModelProvider } from './sub-model-provider';

export class FlatFileBuilder {
  constructor(
    private readonly cells: mxgraph.mxCell[],
    private readonly model: Map<string, MfmConceptConfiguration>,
    private readonly library: Map<string, LibraryModel>,
    private readonly labels: CausalAnalysisItemDescription[],
    private readonly actuators: ModelActuators,
    private readonly reliefSystems: Map<string, string>,
    private readonly sensors: Map<string, Set<SensorStateValue>>,
    private readonly defaultParentId: string,
  ) {}

  public build(): unknown[] {
    const cellMap = toMap(this.cells, 'id');
    const labelMap = toMap(this.labels, 'functionId');

    return (
      Array.from(this.model.values())
        // Filter out some items that should not be in the output file
        .filter(({ concept, parentId }) =>
          it(
            // All items that are inlets and outlets in a Sub-model.
            // They are excluded from the output file
            not(isSUB(() => this.model.get(parentId)?.concept)),
            // All items that are 'notes'.
            // They are going to be placed in the metadata section of the output file
            not(isTXT(() => concept)),
          ),
        )
        .map(mfm => {
          const label = labelMap.get(mfm.id);
          const cell = cellMap.get(mfm.id);

          return new ObjectBuilder<unknown>()
            .use(new MfmBaseProvider(mfm))
            .use(new MfmTypeProvider(mfm)) // For a backward compatibility with CRA
            .use(new MfmDescriptionProvider(mfm))
            .use(new MfmParentProvider(mfm))
            .use(new MfmRelationProvider(mfm))
            .use(new InfluenceRelationProvider(mfm))
            .use(new HazardLevelProvider(mfm))
            .use(new LogicGateVotesProvider(mfm))
            .use(new MfmGroupsProvider(mfm))
            .use(new ObjectRoleProvider(mfm))
            .use(new CausalAnalysisDescriptionProvider(label))
            .use(new GeometryProvider(cell, this.defaultParentId))
            .use(new ProcessVariableProvider(mfm))
            .use(new SensorStateProvider(mfm, this.sensors.get(mfm.id)))
            .use(new ExposeProvider(mfm))
            .use(
              new SubModelProvider(
                mfm,
                this.library.get(`${mfm.subModelGuid}@${mfm.subModelVersion}`),
              ),
            )
            .use(new ObjectiveProvider(mfm))
            .use(new ExtrasProvider(mfm))
            .use(
              new ActuatorProvider(
                mfm,
                this.actuators.automatic.has(mfm.id),
                this.actuators.manual.has(mfm.id),
              ),
            )
            .use(new ReliefSystemProvider(this.reliefSystems.get(mfm.id) ?? ''))
            .get();
        })
    );
  }
}
