<mat-select
    #select
    [formControl]="selectControl"
    multiple
>
    <mat-select-trigger class="multi-select__trigger">
        <span class="multi-select__label">
            {{ value[0] | findOption : options }}
        </span>
        <span
            *ngIf="value?.length > 1"
            class="mat-caption multi-select__other"
        >
            (+{{(value.length || 0) - 1}} {{value.length === 2 ? 'other' : 'others'}})
        </span>
    </mat-select-trigger>
    <mat-option
        *ngIf="options.length > 1"
        (onSelectionChange)="onSelectAll($event)"
        value="-1"
    > Select all </mat-option>
    <mat-option
        *ngFor="let option of options"
        [value]="option.id"
    > {{ option.label }} </mat-option>
</mat-select>
