import {
  isOnlineProcessVariable,
  isVirtualProcessVariable,
  ProcessVariableValue,
} from '@workbench/common/types/process-variable-value.type';
import { ResourceConcept } from '../../common/enums/resource-concept.enum';
import { and, contain, equal, idt, it, or, Predicate } from '../../common/utils/logical-utility';

export const isFunction = (concept: Concept): Predicate => contain(concept, ...functions());
export const isObjective = (concept: Concept): Predicate => contain(concept, ...objectives());
export const isRelation = (concept: Concept): Predicate => contain(concept, ...relations());
export const isStructure = (concept: Concept): Predicate => contain(concept, ...structures());

export const isControlFunction = (concept: ResourceConcept): Predicate =>
  contain(idt(concept), ...controlFunctions());
export const isFlowFunction = (concept: Concept): Predicate => contain(concept, ...flowFunctions());
export const isLogicFunction = (concept: Concept): Predicate =>
  contain(concept, ...logicFunctions());
export const isInfluenceRelation = (concept: Concept): Predicate =>
  contain(concept, ...influenceRelations());
export const isConditionRelation = (concept: Concept): Predicate =>
  contain(concept, ...conditionRelations());
export const isControlRelation = (concept: Concept): Predicate =>
  contain(concept, ...controlRelations());
export const isMeansEndRelation = (concept: Concept): Predicate =>
  contain(concept, ...meansEndRelations());

export const isSensorCandidate = (
  concept: ResourceConcept,
  processVariable: ProcessVariableValue,
): Predicate =>
  and(
    or(
      isBAR(() => concept),
      isSTO(() => concept),
      isTRA(() => concept),
    ),
    or(isOnlineProcessVariable(processVariable), isVirtualProcessVariable(processVariable)),
  );

export const isAC = (concept: Concept): Predicate => equal(concept, AC);
export const isAND = (concept: Concept): Predicate => equal(concept, AND);
export const isBAL = (concept: Concept): Predicate => equal(concept, BAL);
export const isBAR = (concept: Concept): Predicate => equal(concept, BAR);
export const isCFS = (concept: Concept): Predicate => equal(concept, CFS);
export const isDCO = (concept: Concept): Predicate => equal(concept, DCO);
export const isDE = (concept: Concept): Predicate => equal(concept, DE);
export const isEFS = (concept: Concept): Predicate => equal(concept, EFS);
export const isHAZ = (concept: Concept): Predicate => equal(concept, HAZ);
export const isIN = (concept: Concept): Predicate => equal(concept, IN);
export const isIP = (concept: Concept): Predicate => equal(concept, IP);
export const isMEQ = (concept: Concept): Predicate => equal(concept, MEQ);
export const isMFS = (concept: Concept): Predicate => equal(concept, MFS);
export const isPA = (concept: Concept): Predicate => equal(concept, PA);
export const isPP = (concept: Concept): Predicate => equal(concept, PP);
export const isSHA = (concept: Concept): Predicate => equal(concept, SHA);
export const isSIN = (concept: Concept): Predicate => equal(concept, SIN);
export const isSOU = (concept: Concept): Predicate => equal(concept, SOU);
export const isSTO = (concept: Concept): Predicate => equal(concept, STO);
export const isSUB = (concept: Concept): Predicate => equal(concept, SUB);
export const isSYS = (concept: Concept): Predicate => equal(concept, SYS);
export const isTAR = (concept: Concept): Predicate => equal(concept, TAR);
export const isTRA = (concept: Concept): Predicate => equal(concept, TRA);
export const isTXT = (concept: Concept): Predicate => equal(concept, TXT);
export const isVOR = (concept: Concept): Predicate => equal(concept, VOR);

type Concept = () => ResourceConcept;
// prettier-ignore
const conceptId = (concept: ResourceConcept): Concept => (): ResourceConcept => concept;

//#region Concepts
const AC = conceptId(ResourceConcept.Ac);
const BAL = conceptId(ResourceConcept.Bal);
const BAR = conceptId(ResourceConcept.Bar);
const CFS = conceptId(ResourceConcept.Cfs);
const DCO = conceptId(ResourceConcept.Dco);
const DE = conceptId(ResourceConcept.De);
const DI = conceptId(ResourceConcept.Di);
const SYS = conceptId(ResourceConcept.Sys);
const EFS = conceptId(ResourceConcept.Efs);
const EN = conceptId(ResourceConcept.En);
const HAZ = conceptId(ResourceConcept.Haz);
const IN = conceptId(ResourceConcept.In);
const IP = conceptId(ResourceConcept.Ip);
const MA = conceptId(ResourceConcept.Ma);
const MCO = conceptId(ResourceConcept.Mco);
const ME = conceptId(ResourceConcept.Me);
const MEQ = conceptId(ResourceConcept.Meq);
const MFS = conceptId(ResourceConcept.Mfs);
const PA = conceptId(ResourceConcept.Pa);
const PCO = conceptId(ResourceConcept.Pco);
const PP = conceptId(ResourceConcept.Pp);
const PR = conceptId(ResourceConcept.Pr);
const SHA = conceptId(ResourceConcept.Sha);
const SIN = conceptId(ResourceConcept.Sin);
const SOU = conceptId(ResourceConcept.Sou);
const STO = conceptId(ResourceConcept.Sto);
const SU = conceptId(ResourceConcept.Su);
const SUB = conceptId(ResourceConcept.Sub);
const SUP = conceptId(ResourceConcept.Sup);
const TAR = conceptId(ResourceConcept.Tar);
const TRA = conceptId(ResourceConcept.Tra);
const TXT = conceptId(ResourceConcept.Txt);
const VOR = conceptId(ResourceConcept.Vor);
const AND = conceptId(ResourceConcept.And);
//#endregion

const conditionRelations = (): Concept[] => [EN, DI];
const controlFunctions = (): Concept[] => [PCO, DCO, MCO, SUP];
const controlRelations = (): Concept[] => [AC];
const flowFunctions = (): Concept[] => [BAL, BAR, SIN, SOU, STO, TRA];
const logicFunctions = (): Concept[] => [AND, VOR];
const influenceRelations = (): Concept[] => [PA, IN, SHA];
const meansEndRelations = (): Concept[] => [DE, IP, MA, ME, PP, PR, SU];

const functions = (): Concept[] => [...flowFunctions(), ...controlFunctions(), ...logicFunctions()];
const objectives = (): Concept[] => [HAZ, TAR];
const relations = (): Concept[] => [
  ...conditionRelations(),
  ...controlRelations(),
  ...influenceRelations(),
  ...meansEndRelations(),
];
const structures = (): Concept[] => [SYS, MEQ, MFS, EFS, CFS];

///
/// MFM Connections
///

/**
 * Check should the provided relation be handled in a special way.
 * Requires a set of MFM concepts that represent a source, target, relation between them, and a parent of the source and target  (if has one).
 *
 * @param source
 * @param relation
 * @param target
 * @param sourceParent
 * @param targetParent
 * @returns a function that points to the end that should be shifted (source or target).
 * 'none' means that we deal with a regular connection
 */
export const findSpecialConnectionEnd = (
  source: Concept,
  relation: Concept,
  target: Concept,
  sourceParent: Concept,
  targetParent: Concept,
): 'source' | 'target' | 'none' => {
  //#region Conditions
  // prettier-ignore
  const empty = (array:  unknown[]): Predicate => (): boolean => array.length === 0;
  const validSources = (x: SpecialConnectionDescriptor): Concept[] => x[0];
  const validRelations = (x: SpecialConnectionDescriptor): Concept[] => x[1];
  const validTargets = (x: SpecialConnectionDescriptor): Concept[] => x[2];
  const validSourceParents = (x: SpecialConnectionDescriptor): Concept[] => x[3];
  const validTargetParents = (x: SpecialConnectionDescriptor): Concept[] => x[4];
  const overlaps = (value: Concept, valid: Concept[]): Predicate =>
    or(empty(valid), contain(value, ...valid));
  //#endregion

  const connection = specialConnections.find(s =>
    it(
      overlaps(source, validSources(s)),
      overlaps(relation, validRelations(s)),
      overlaps(target, validTargets(s)),
      overlaps(sourceParent, validSourceParents(s)),
      overlaps(targetParent, validTargetParents(s)),
    ),
  );

  return connection?.[5] ?? 'none';
};

// An array of special relations. Each item of an array represent the next:
// [
//   [acceptable sources],
//   [acceptable relation],
//   [acceptable targets],
//   [acceptable source parent],
//   [acceptable target parent],
//   flag - it indicates an end (source or target) that should be shifted
// ]
//#region Special Connection Descriptor
type SpecialConnectionDescriptor = [
  Concept[],
  Concept[],
  Concept[],
  Concept[],
  Concept[],
  'source' | 'target',
];

// prettier-ignore
const specialConnections: SpecialConnectionDescriptor[] = [
  [objectives(), conditionRelations(), controlFunctions(), [], [CFS, EFS, MFS], 'target'],
  [objectives(), conditionRelations(), flowFunctions(), [], [CFS, EFS, MFS], 'target'],
  [controlFunctions(), conditionRelations(), objectives(), [CFS, EFS, MFS], [], 'source'],
  [flowFunctions(), conditionRelations(), objectives(), [CFS, EFS, MFS], [], 'source'],
  [flowFunctions(), meansEndRelations(), objectives(), [CFS, EFS, MFS], [], 'source'],
  [controlFunctions(), meansEndRelations(), objectives(), [CFS, EFS, MFS], [], 'source'],

  [flowFunctions(), controlRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'target'],
  [flowFunctions(), controlRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'target'],
  [controlFunctions(), controlRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'target'],
  [controlFunctions(), controlRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'target'],

  [flowFunctions(), conditionRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [flowFunctions(), conditionRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [controlFunctions(), conditionRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [controlFunctions(), conditionRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],

  [flowFunctions(), meansEndRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [flowFunctions(), meansEndRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [controlFunctions(), meansEndRelations(), flowFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
  [controlFunctions(), meansEndRelations(), controlFunctions(), [CFS, EFS, MFS], [CFS, EFS, MFS], 'source'],
];
//#endregion
