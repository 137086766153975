import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';

export class ExtrasProvider extends ObjectBuilderProvider<unknown> {
  constructor(private readonly source: { concept: ResourceConcept; extras: unknown }) {
    super();
  }

  public get(): { extras: unknown } | null {
    if (this.source.extras) {
      return {
        extras: this.source.extras,
      };
    }

    return null;
  }
}
