import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { it } from '@workbench/common/utils/logical-utility';
import { isInfluenceRelation } from '../core/mfm-core';

export class InfluenceRelationProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; linkTargetPortName: string }) {
    super();
  }

  public get(): MfmConcept {
    const concept = (): ResourceConcept => this.source.concept;

    if (it(isInfluenceRelation(concept))) {
      return {
        endPort:
          this.source.linkTargetPortName === 'in'
            ? 'upstream'
            : this.source.linkTargetPortName === 'out'
            ? 'downstream'
            : '',
      } as MfmConcept;
    }
  }
}
