import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@workbench/common/models/option';

@Pipe({
  name: 'findOption',
})
export class FindOptionPipe implements PipeTransform {
  public transform(value: string, values: Option[]): string {
    if (value == null) {
      return '';
    }

    return values.find(x => x.id === value)?.label ?? '';
  }
}
