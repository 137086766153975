import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { ProcessVariableValue } from '@workbench/common/types/process-variable-value.type';
import { SensorStateValue } from '@workbench/common/types/sensor-state.type';
import { it } from '@workbench/common/utils/logical-utility';
import { isSensorCandidate } from '../core/mfm-core';

export class SensorStateProvider extends ObjectBuilderProvider<unknown> {
  constructor(
    private readonly source: {
      concept: ResourceConcept;
      processVariable: ProcessVariableValue;
    },
    private readonly sensorState: Set<SensorStateValue> | undefined,
  ) {
    super();
  }

  public get():
    | {
        processVariableState: SensorStateValue[];
      }
    | undefined {
    if (it(isSensorCandidate(this.source.concept, this.source.processVariable))) {
      return {
        processVariableState: Array.from(this.sensorState ?? []),
      };
    }
  }
}
