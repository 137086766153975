import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';

export class ReliefSystemProvider extends ObjectBuilderProvider<unknown> {
  constructor(private readonly reliefValveId: string) {
    super();
  }

  public get(): { reliefSystem: 'RS'; reliefValveId: string } | undefined {
    if (this.reliefValveId !== '') {
      return {
        reliefSystem: 'RS',
        reliefValveId: this.reliefValveId,
      };
    }
  }
}
