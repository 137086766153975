import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CausalAnalysisListKind } from './causal-analysis-list-kind';
import { CausalAnalysisListItem } from './list-item.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wb-causal-analysis-list',
  styleUrls: ['./causal-analysis-list.component.scss'],
  templateUrl: './causal-analysis-list.component.html',
})
export class CausalAnalysisListComponent {
  @Input() public items: CausalAnalysisListItem[] = [];
  @Input() public kind: CausalAnalysisListKind = CausalAnalysisListKind.Causes;
  @Input() public selectedItem = -1;
  @Output() public readonly selectionChanged = new EventEmitter<number>();

  public readonly CAUSES = CausalAnalysisListKind.Causes;
  public readonly CONSEQUENCES = CausalAnalysisListKind.Consequences;

  public onSelectionChange(index: number): void {
    if ((this.items[index].unreachable ?? false) === false) {
      this.selectionChanged.emit(index);
    }
  }

  public trackBy(index: number): number {
    return index;
  }
}
