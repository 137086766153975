import { ObjectBuilderProvider } from '@workbench/common/converter/object-builder-provider';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { MfmConcept } from '@workbench/common/models/mfm-concept.model';
import { ExposureState } from '@workbench/common/types/exposure-state-value.type';
import { it } from '@workbench/common/utils/logical-utility';
import { hasAttribute } from '../core/mfm-attributes';

export class ExposeProvider extends ObjectBuilderProvider<MfmConcept> {
  constructor(private readonly source: { concept: ResourceConcept; exposed: ExposureState }) {
    super();
  }

  public get(): MfmConcept | undefined {
    if (it(hasAttribute(this.source.concept, 'exposed'))) {
      return {
        exposed: this.source.exposed ?? false,
      } as MfmConcept;
    }
  }
}
