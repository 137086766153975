<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
    @if (message.length > 0) {
    <p>{{ message }}</p>
    }
    @if (additionalInformation.length > 0) {
    <p
        class="confirmation__additional"
        color="warn"
    > {{ additionalInformation }} </p>
    }
</div>
<div
    class="confirmation__actions"
    mat-dialog-actions
>
    <button
        mat-stroked-button
        (click)="onCancel()"
    >Cancel</button>
    <button
        *ngIf="reject.length > 0"
        mat-stroked-button
        (click)="onReject()"
    >
        {{ reject }}
    </button>
    <button
        mat-flat-button
        color="warn"
        (click)="onConfirm()"
    >
        {{ confirm }}
    </button>
</div>
