import { assignMap } from '@workbench/common/utils/map-util';

/// <summary>
/// Returns the style value for the given style name.
/// </summary>
export const getStyle = (style: string, styleName: string, fallback?: string): string =>
  stylesMap(style).get(styleName) ?? fallback ?? '';

/// <summary>
/// Returns the new style string merged with the given style string.
/// </summary>
export const assignStyle = (style: string, update: string): string =>
  toString(assignMap(stylesMap(style), stylesMap(update)));

/// <summary>
/// Returns the new style string without the given style(s).
/// </summary>
export const removeStyle = (style: string, ...name: string[]): string => {
  const m = stylesMap(style);

  name.forEach(x => m.delete(x));

  return toString(m);
};

/// <summary>
/// Returns the new style string updated with the given style name and value.
/// </summary>
export const updateStyle = (style: string, styleName: string, value: string): string =>
  toString(stylesMap(style).set(styleName, value));

const stylesMap = (style: string): Map<string, string> =>
  new Map(
    style
      .split(';')
      .map(x => x.split('='))
      .filter(x => x.length === 2 && x[0].trim().length > 0) as [string, string][],
  );

const toString = (styles: Map<string, string>): string =>
  Array.from(styles)
    .map(x => x.join('='))
    .join(';');
