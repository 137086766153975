import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { SeverityLevel } from '@workbench/common/enums/severity-level.enum';
import { SensorStateValue } from '@workbench/common/types/sensor-state.type';
import { it } from '@workbench/common/utils/logical-utility';
import { sorted } from '@workbench/common/utils/set-util';
import { isSensorCandidate } from '@workbench/multilevel-flow-modeling/core/mfm-core';
import { getConnectedVertices } from '@workbench/multilevel-flow-modeling/core/mfm-util';
import { MfmConceptConfiguration } from '../mfm-model.model';

/**
 * Goes through the MFM model and seeks sensors.
 * Any STO, TRA, or BAR function could be a Trip or Alarm if:
 * 1. It is Transmitter or Virtual process variable
 * 2. It is connected to a HAZ with a DE relation
 * 3. HAZ has only two connections
 *    and is connected to a DCO with either PA or IN relation
 * 4. DCO doesn't have any other connections
 *
 * @param model MFM model
 * @returns a Map of sensor ids and their states
 */
export function selectSensors(
  model: Map<string, MfmConceptConfiguration>,
): Map<string, Set<SensorStateValue>> {
  const sensors = Array.from(model.values())
    .filter(({ concept, processVariable }) => it(isSensorCandidate(concept, processVariable)))
    .map(({ id }) => id);

  return sensors.reduce((map, id) => {
    // prettier-ignore
    const hazards = getConnectedVertices(id, model, 'target', new Set([ResourceConcept.De]), new Set([ResourceConcept.Haz]));

    hazards
      .filter(x => {
        // HAZ has to be connected with two items only
        if (getConnectedVertices(x.id, model).length !== 2) {
          return false;
        }
        // prettier-ignore
        const [dco] = getConnectedVertices(x.id, model, 'target', new Set([ResourceConcept.In]), new Set([ResourceConcept.Dco]));

        // HAZ has to be connected with a DCO
        if ((dco ?? null) === null) {
          return false;
        }

        // DCO has to be connected with the one and only one item (HAZ)
        return getConnectedVertices(dco.id, model).length === 1;
      })
      .forEach(x => {
        if (map.has(id) === false) {
          map.set(id, new Set());
        }
        if (x.level === SeverityLevel.Low) {
          map.get(id).add('LL');
        }
        if (x.level === SeverityLevel.High) {
          map.get(id).add('HH');
        }
      });
    hazards
      .filter(x => {
        // HAZ has to be connected with two items only
        if (getConnectedVertices(x.id, model).length !== 2) {
          return false;
        }
        // prettier-ignore
        const [dco] = getConnectedVertices(x.id, model, 'target', new Set([ResourceConcept.Pa]), new Set([ResourceConcept.Dco]));

        // HAZ has to be connected with a DCO
        if ((dco ?? null) === null) {
          return false;
        }

        // DCO has to be connected with the one and only one item (HAZ)
        return getConnectedVertices(dco.id, model).length === 1;
      })
      .forEach(({ level }) => {
        if (map.has(id) === false) {
          map.set(id, new Set());
        }
        if (level === SeverityLevel.Low) {
          map.get(id).add('L');
        }
        if (level === SeverityLevel.High) {
          map.get(id).add('H');
        }
      });

    // Sort the states alphabetically if there is more than one value
    if (map.has(id) && map.get(id).size > 1) {
      map.set(id, sorted(map.get(id)));
    }

    return map;
  }, new Map<string, Set<SensorStateValue>>());
}
