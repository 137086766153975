import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CardComponent } from './components/card/card.component';
import { CausalAnalysisListComponent } from './components/causal-analysis-list/causal-analysis-list.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { OptionAndDescriptionEditorComponent } from './components/option-and-description-editor/option-and-description-editor.component';
import { TagEditorComponent } from './components/tag-editor/tag-editor.component';
import { TaskMonitorComponent } from './components/task-monitor/task-monitor.component';
import { TooltipWrapperComponent } from './components/tooltip-wrapper/tooltip-wrapper.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConceptIconPipe } from './pipes/concept-icon.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { EqualsPipe } from './pipes/equals.pipe';
import { FallbackPipe } from './pipes/fallback.pipe';
import { FindOptionPipe } from './pipes/find-option.pipe';
import { AccessGrantedPipe } from './pipes/granted.pipe';
import { ReasoningFaultKindIconPipe } from './pipes/reasoning-fault-kind-icon.pipe';
import { NotificationComponent } from './templates/notification/notification.component';

@NgModule({
  declarations: [
    AccessGrantedPipe,
    AutocompleteComponent,
    BadgeComponent,
    CardComponent,
    CausalAnalysisListComponent,
    ConceptIconPipe,
    ConfirmationComponent,
    EqualsPipe,
    FallbackPipe,
    FindOptionPipe,
    MultiSelectComponent,
    NotificationComponent,
    OptionAndDescriptionEditorComponent,
    ReasoningFaultKindIconPipe,
    TagEditorComponent,
    TaskMonitorComponent,
    TooltipWrapperComponent,
    ContainsPipe,
  ],
  exports: [
    AccessGrantedPipe,
    AutocompleteComponent,
    BadgeComponent,
    CardComponent,
    CausalAnalysisListComponent,
    CommonModule,
    ConceptIconPipe,
    EqualsPipe,
    FallbackPipe,
    FindOptionPipe,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MultiSelectComponent,
    OptionAndDescriptionEditorComponent,
    ReactiveFormsModule,
    ReasoningFaultKindIconPipe,
    RouterModule,
    TagEditorComponent,
    TaskMonitorComponent,
    TooltipWrapperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
