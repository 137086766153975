import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { SeverityLevel } from '@workbench/common/enums/severity-level.enum';

import {
  isProcessVariable,
  isVirtualProcessVariable,
} from '@workbench/common/types/process-variable-value.type';
import { and, idt, it, or, Predicate } from '@workbench/common/utils/logical-utility';
import { MfmConceptConfiguration } from '@workbench/state/model-builder/mfm-model.model';
import {
  isBAR,
  isCFS,
  isControlFunction,
  isEFS,
  isFunction,
  isHAZ,
  isLogicFunction,
  isMEQ,
  isMFS,
  isObjective,
  isRelation,
  isSHA,
  isSIN,
  isSOU,
  isSTO,
  isStructure,
  isSUB,
  isSYS,
  isTAR,
  isTRA,
  isVOR,
} from './mfm-core';

export function getConceptAttributes(
  concept: ResourceConcept,
  add?: Partial<MfmConceptConfiguration>,
): (keyof MfmConceptConfiguration)[] {
  return attributes().filter(attr => it(hasAttribute(concept, attr, add)));
}

export const getDefaultConcept = (
  init?: Partial<MfmConceptConfiguration>,
): MfmConceptConfiguration => ({
  actuatorTag: init?.actuatorTag ?? '',
  actuatorType: init?.actuatorType ?? '',
  category: init?.category ?? '',
  comment: init?.comment ?? '',
  concept: init?.concept ?? ResourceConcept.Undef,
  description: init?.description ?? '',
  equipment: init?.equipment ?? '',
  exposed: init?.exposed ?? false,
  expression: init?.expression ?? '',
  extras: init?.extras ?? null,
  id: init?.id ?? '',
  instrumentTag: init?.instrumentTag ?? '',
  label: init?.label ?? '',
  level: init?.level ?? '',
  levelComponentLabel: init?.levelComponentLabel ?? '',
  levelComponentTag: init?.levelComponentTag ?? '',
  levelPartLabel: init?.levelPartLabel ?? '',
  levelPartTag: init?.levelPartTag ?? '',
  levelSubUnitLabel: init?.levelSubUnitLabel ?? '',
  levelSubUnitTag: init?.levelSubUnitTag ?? '',
  linkSourceId: init?.linkSourceId ?? '',
  linkSourcePortName: init?.linkSourcePortName ?? '',
  linkTargetId: init?.linkTargetId ?? '',
  linkTargetPortName: init?.linkTargetPortName ?? '',
  logicGateVotes: init?.logicGateVotes ?? null,
  mainFunction: init?.mainFunction ?? null,
  objectRole: init?.objectRole ?? '',
  parentId: init?.parentId ?? '',
  pipingInstrumentationDiagram: init?.pipingInstrumentationDiagram ?? '',
  processVariable: init?.processVariable ?? false,
  subModelCanBeUpdated: init?.subModelCanBeUpdated ?? false,
  subModelGuid: init?.subModelGuid ?? '',
  subModelName: init?.subModelName ?? '',
  subModelTerminals: init?.subModelTerminals ?? null,
  subModelTagging: init?.subModelTagging ?? null,
  subModelUpdateAvailable: init?.subModelUpdateAvailable ?? false,
  subModelVersion: init?.subModelVersion ?? '',
  systemLabel: init?.systemLabel ?? '',
  systemTag: init?.systemTag ?? '',
  tag: init?.tag ?? '',
});

export const getDefaults = (concept: ResourceConcept): Partial<MfmConceptConfiguration> => {
  const defaults = Object.create(null);

  if (it(hasAttribute(concept, 'level'))) {
    Object.assign(defaults, { level: SeverityLevel.High });
  }
  if (it(hasAttribute(concept, 'exposed'))) {
    // It is false by default, but for SOU and SIN it is true
    const exposed = it(or(isSOU(idt(concept)), isSIN(idt(concept))));

    Object.assign(defaults, { exposed });
  }
  if (it(hasAttribute(concept, 'processVariable'))) {
    Object.assign(defaults, { processVariable: false });
  }

  return defaults;
};

export function hasAttribute(
  concept: ResourceConcept,
  prop: keyof Pick<MfmConceptConfiguration, 'expression' | 'instrumentTag'>,
  add: Pick<MfmConceptConfiguration, 'processVariable'>,
): Predicate;
export function hasAttribute(
  concept: ResourceConcept,
  prop: keyof Omit<MfmConceptConfiguration, 'expression' | 'instrumentTag'>,
): Predicate;
export function hasAttribute(
  concept: ResourceConcept,
  prop: keyof (Pick<MfmConceptConfiguration, 'expression' | 'instrumentTag'> &
    Omit<MfmConceptConfiguration, 'expression' | 'instrumentTag'>),
  add: Partial<MfmConceptConfiguration>,
): Predicate;
export function hasAttribute(
  concept: ResourceConcept,
  prop: keyof MfmConceptConfiguration,
  add?: Partial<MfmConceptConfiguration>,
): Predicate {
  return hasAttributeImpl(concept, prop, add);
}

function hasAttributeImpl(
  concept: ResourceConcept,
  prop: keyof MfmConceptConfiguration,
  add?: Partial<MfmConceptConfiguration>,
): Predicate {
  switch (prop) {
    // The `actuatorTag` is considered as an additional field to Barrier or Transport
    // when it has a special connection with a Control Function
    case 'actuatorTag':
    case 'actuatorType': {
      return or(isBAR(idt(concept)), isTRA(idt(concept)));
    }
    case 'category': {
      return or(isHAZ(idt(concept)), isTAR(idt(concept)));
    }
    case 'comment':
    case 'concept':
    case 'id': {
      return idt(true);
    }
    case 'description':
    case 'extras':
    case 'label': {
      return or(
        isSUB(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
        isRelation(idt(concept)),
        isStructure(idt(concept)),
      );
    }
    case 'equipment': {
      return or(
        isSUB(idt(concept)),
        isMEQ(idt(concept)),
        isCFS(idt(concept)),
        isEFS(idt(concept)),
        isMFS(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
      );
    }
    case 'exposed': {
      return or(
        isBAR(idt(concept)),
        isHAZ(idt(concept)),
        isSIN(idt(concept)),
        isSOU(idt(concept)),
        isSTO(idt(concept)),
        isTAR(idt(concept)),
        isTRA(idt(concept)),
      );
    }
    // Special case for the `expression` attribute.
    // We suppose that it's an addition to the `processVariable` property,
    // and it's intrinsic for every concept that can hold `processVariable`.
    // But it is visible only
    // if the concept's processVariable value is 'virtual'.
    case 'expression': {
      return and(
        isVirtualProcessVariable(add?.processVariable),
        or(
          isControlFunction(concept),
          isTRA(idt(concept)),
          isSTO(idt(concept)),
          isBAR(idt(concept)),
          isSIN(idt(concept)),
          isSOU(idt(concept)),
        ),
      );
    }
    // Special case for the `instrumentTag` attribute.
    // We suppose that it's an addition to the `processVariable` property,
    // and it's intrinsic for every concept that can hold `processVariable`.
    // But it is visible in Properties Editor only
    // if the concept's processVariable value differs from 'false' (online/offline/virtual).
    // On the other side, `instrumentTag` is a property of the Control Function
    case 'instrumentTag': {
      return or(
        isControlFunction(concept),
        and(
          isProcessVariable(add?.processVariable),
          or(
            isTRA(idt(concept)),
            isSTO(idt(concept)),
            isBAR(idt(concept)),
            isSIN(idt(concept)),
            isSOU(idt(concept)),
          ),
        ),
      );
    }
    case 'levelComponentLabel':
    case 'levelComponentTag':
    case 'levelPartLabel':
    case 'levelPartTag':
    case 'levelSubUnitLabel':
    case 'levelSubUnitTag': {
      return or(
        isSUB(idt(concept)),
        isMEQ(idt(concept)),
        isCFS(idt(concept)),
        isEFS(idt(concept)),
        isMFS(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
      );
    }
    case 'logicGateVotes': {
      return isVOR(idt(concept));
    }
    case 'parentId': {
      return or(
        isMEQ(idt(concept)),
        isCFS(idt(concept)),
        isEFS(idt(concept)),
        isMFS(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
      );
    }
    case 'pipingInstrumentationDiagram': {
      return or(
        isSUB(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
        isStructure(idt(concept)),
      );
    }
    case 'processVariable': {
      return or(
        isControlFunction(concept),
        isTRA(idt(concept)),
        isSTO(idt(concept)),
        isBAR(idt(concept)),
        isSIN(idt(concept)),
        isSOU(idt(concept)),
      );
    }
    case 'level': {
      return isHAZ(idt(concept));
    }
    case 'linkSourceId':
    case 'linkSourcePortName':
    case 'linkTargetId':
    case 'linkTargetPortName':
    case 'mainFunction': {
      return isRelation(idt(concept));
    }
    case 'objectRole': {
      return or(
        isSOU(idt(concept)),
        isSIN(idt(concept)),
        isSHA(idt(concept)),
        isTAR(idt(concept)),
        isHAZ(idt(concept)),
      );
    }
    case 'subModelGuid':
    case 'subModelCanBeUpdated':
    case 'subModelName':
    case 'subModelTagging':
    case 'subModelTerminals':
    case 'subModelUpdateAvailable':
    case 'subModelVersion': {
      return isSUB(idt(concept));
    }
    case 'systemLabel':
    case 'systemTag': {
      return or(
        isSYS(idt(concept)),
        isSUB(idt(concept)),
        isMEQ(idt(concept)),
        isCFS(idt(concept)),
        isEFS(idt(concept)),
        isMFS(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
        isLogicFunction(idt(concept)),
      );
    }
    case 'tag': {
      return or(
        isSUB(idt(concept)),
        isMEQ(idt(concept)),
        isCFS(idt(concept)),
        isEFS(idt(concept)),
        isMFS(idt(concept)),
        isFunction(idt(concept)),
        isObjective(idt(concept)),
        isLogicFunction(idt(concept)),
      );
    }
    default: {
      throw new Error(`ArgumentError ${prop}`);
    }
  }
}

const attributes = (): (keyof MfmConceptConfiguration)[] => [
  'actuatorTag',
  'actuatorType',
  'category',
  'comment',
  'concept',
  'description',
  'equipment',
  'exposed',
  'expression',
  'extras',
  'id',
  'instrumentTag',
  'label',
  'level',
  'levelComponentLabel',
  'levelComponentTag',
  'levelPartLabel',
  'levelPartTag',
  'levelSubUnitLabel',
  'levelSubUnitTag',
  'linkSourceId',
  'linkSourcePortName',
  'linkTargetId',
  'linkTargetPortName',
  'logicGateVotes',
  'mainFunction',
  'objectRole',
  'parentId',
  'pipingInstrumentationDiagram',
  'processVariable',
  'subModelCanBeUpdated',
  'subModelGuid',
  'subModelName',
  'subModelTagging',
  'subModelTerminals',
  'subModelUpdateAvailable',
  'subModelVersion',
  'systemLabel',
  'systemTag',
  'tag',
];
