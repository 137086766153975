import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ResourceConcept } from '@workbench/common/enums/resource-concept.enum';
import { LibraryTagging } from '@workbench/common/models/library-tagging.model';
import {
  setLibraryModel,
  setLibraryModels,
  setLibraryModelTags,
  updateLibraryModels,
  updateLibraryModelTags,
} from './library.actions';

export { adapter, LibraryModel, LibraryState };

const adapter: EntityAdapter<LibraryModel> = createEntityAdapter<LibraryModel>({
  selectId: s => `${s.guid}@${s.version}`,
});

type LibraryState = EntityState<LibraryModel>;
type LibraryModel = LibraryModelIdentifier & Partial<LibraryModelProps>;

interface LibraryModelIdentifier {
  guid: string;
  version: string;
}

interface LibraryModelProps {
  asset: string;
  comment: string;
  folder: string;
  input: Port[];
  name: string;
  output: Port[];
  sinks: Port[];
  sources: Port[];
  tag: string;
  tagging: LibraryTagging;
}

interface Port {
  badge: string[];
  concept: ResourceConcept;
  id: string;
  label: string;
}

export const libraryReducer = createReducer(
  adapter.getInitialState(),

  on(setLibraryModels, (state, { models }) => adapter.upsertMany(models, state)),
  on(setLibraryModelTags, (state, { key, tagging }) =>
    adapter.updateOne({ id: key, changes: { tagging } }, state),
  ),
  on(setLibraryModel, (state, { model }) => adapter.upsertOne(model, state)),
  on(updateLibraryModels, (state, { models }) =>
    adapter.updateMany(
      models.map(x => ({
        id: adapter.selectId(x).toString(),
        changes: x,
      })),
      state,
    ),
  ),
  on(updateLibraryModelTags, (state, { key, tagging }) =>
    adapter.updateOne({ id: key, changes: { tagging } }, state),
  ),
);
